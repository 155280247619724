import { Box, Button, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { setDeligateAccessRequest } from '../redux/actions/deligateAccessActions';
import { useNavigate, useParams } from 'react-router-dom';

const DeligateAccess = () => {
    const params = useParams();
    const [ownerNumber, setOwnerNumber] = useState(params?.id);
    const [workerNumber, setWorkerNumber] = useState('');
    const [flag, setFlag] = useState(false);
    const [showNavigateButton, setShowNavigateButton] = useState(false);
    const [accountAccessDetails, setAccountAccessDetails] = useState([]);

    const handleSubmit = async () => {
        console.log('Owner Number:', ownerNumber);
        console.log('Worker Number:', workerNumber);
        console.log('Flag:', flag);
        const body = { owner_mobile: ownerNumber, worker_mobile: workerNumber, tenant: "netaji", createNewAccountFlag: flag };
        const response = await setDeligateAccessRequest(body);
        console.log('Response:', response);
        if (response?.status === 200) {
            setShowNavigateButton(true);
            setAccountAccessDetails(response?.data?.data);
        }
    };
    const navigate = useNavigate();
    const handleRedirectToDeligateAccount = (targetUrl) => {

        
        window.location.href = targetUrl
      }

    return (
        <Grid container spacing={2}>
            <Grid
                item
                xs={12}
                md={12}
                sx={{
                    padding: 2,
                    borderRadius: '8px',
                    backgroundColor: '#E2EAF4',
                    marginTop: { md: 5, xs: 2 },
                    marginLeft: { md: 10, xs: 0 },
                    marginRight: { md: 10, xs: 0 },
                }}
            >
                <Box
                    sx={{
                        backgroundColor: '#eceff1',
                        padding: 2,

                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                        <Typography>Deligate Access</Typography>

                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                            <TextField
                                label="Owner Number"
                                value={ownerNumber}
                                onChange={(e) => setOwnerNumber(e.target.value)}
                                fullWidth
                            />
                            <TextField
                                label="Worker Number"
                                value={workerNumber}
                                onChange={(e) => setWorkerNumber(e.target.value)}
                                fullWidth
                            />
                        </Box>

                    </Box>

                    <Box sx={{ marginTop: 3, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Button variant="contained" onClick={handleSubmit} >
                            Submit
                        </Button>
                        {showNavigateButton && <Button variant="contained" sx={{ marginLeft: 2 }}
                            onClick={()=> handleRedirectToDeligateAccount(accountAccessDetails?.url)}
                        >
                            Access Account
                        </Button>
                        }
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
};

export default DeligateAccess;