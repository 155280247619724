import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, } from 'react-redux'
import { Box, Button, Grid, MenuItem, Typography, TextField, FormControl, Select, InputLabel } from '@mui/material';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import { editMultiCardValueData, getMultiCardValueData } from '../../redux/actions/multiCardValueActions';

function EditMultiCardValue() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [cardDetails, setCardDetails] = useState(null);
    const params = useParams();

    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        const fetchMultiCardValueData = async () => {
            setLoading(true);
            const cardData = await dispatch(getMultiCardValueData(params?.id));
            if (cardData) {
                setCardDetails(cardData);
            }
            console.log("Multi-card value:", cardData);
            setLoading(false);
        };
        fetchMultiCardValueData();
    }, [dispatch, params?.id]);

    const handleFieldChange = (targetField, event) => {
        setCardDetails((previousCardValues) => {
            const updatedRows = previousCardValues?.data?.rows?.map((cardField) => {
                if (cardField?.field_name === targetField?.field_name) {
                    return { ...cardField, field_value: event.target.value };
                }
                return cardField;
            });

            return {
                ...previousCardValues,
                data: {
                    ...previousCardValues.data,
                    rows: updatedRows,
                },
            };
        });
    };

    const handleEditSubmit = async () => {
        const body = {
            card_fields: cardDetails?.data?.rows.map(({ field_name, id, field_value }) => ({
                field_name,
                id,
                field_value: field_value || "",
            })),
            id: params?.id,
        };

        try {
            const rsp = await editMultiCardValueData(body);
            if (rsp.status === "success") {
                navigate(`/dashboard/digital-cards`);
            } else {
                console.error("Edit failed:", rsp.message);
            }
        } catch (error) {
            console.error("Error during submission:", error);
        }
    };

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
                <Typography variant="h4">Edit MultiCard Card</Typography>
            </Box>
            {loading ? (
                <Loading />
            ) : (
                <Grid
                    item
                    xs={12}
                    md={5}
                    sx={{
                        padding: 2,
                        borderRadius: '8px',
                        backgroundColor: '#E2EAF4',
                        marginLeft: { md: 30 },
                        marginRight: { md: 30 },
                        marginTop: 5,
                    }}
                >
                    {cardDetails?.data?.rows?.length > 0 ? (
                        <>
                            {cardDetails?.data?.rows?.map((field, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        backgroundColor: '#eceff1',
                                        padding: 2,
                                        color: 'primary',
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        name={field?.field_name}
                                        id={field?.id}
                                        label={field?.field_name}
                                        placeholder={field?.field_name}
                                        type={field?.field_type}
                                        value={field.field_value || ''}
                                        onChange={(event) => handleFieldChange(field, event)}
                                        InputLabelProps={{ shrink: true }}
                                        autoFocus
                                    />
                                </Box>
                            ))}
                            <Button
                                variant="contained"
                                color="error"
                                sx={{ marginTop: 2, width: '100%' }}
                                onClick={handleEditSubmit}
                                
                            >
                                Submit
                            </Button>
                        </>
                    ) : (
                        <Typography>
                            This card is restricted. To get this card, WhatsApp us on 876514002 or email us at support@awesomeinnovators.com
                        </Typography>
                    )}
                </Grid>
            )}

        </div>
    )
}

export default EditMultiCardValue