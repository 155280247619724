import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";
import { toast } from 'react-toastify';

export const fetchUserDataSuccess = (data) => ({
  type: actionTypes.FETCH_USER_DATA_SUCCESS,
  payload: data,
});


let refId = '';
export const LogIn = (formData, navigate, setLoading) => async (dispatch) => {
  toast.success("attempting to log in");
  dispatch({ type: actionTypes.CLEAR_ERRORS });
  setLoading(true);
  try {
    const { data, status } = await api.login(formData);
    if (status === 200) {
      refId = data?.data.refId;
      dispatch({ type: actionTypes.SHOW_OTP_INPUT, payload: { showOtpInput: true } });

      dispatch(fetchUserDataSuccess(data));

      toast.success(data.message);
      console.log(data);
      return data;
    } else {
      // dispatch({ type: actionTypes.SET_ERRORS, payload: data });
    }
    setLoading(false);
  } catch (error) {
    //   dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    setLoading(false);
  }
};

export const fetchUserDetailsSuccess = (data) => ({
  type: actionTypes.FETCH_USER_DETAILS_SUCCESS,
  payload: data,
});

export const verifyOtp = (formData, navigate, setLoading) => async (dispatch) => {
  setLoading(true);
  try {
    const { data, status } = await api.verifyOtp({
      ...formData,
      refId: refId,
  });
  console.log("data after verify", data);
    if (status === 200) {
      dispatch({ type: actionTypes.LOG_IN, payload: data?.data });
      toast.success("OTP Verified Successfully");
      navigate("/dashboard"); // Redirect on success
      // dispatch(fetchUserDetailsSuccess(data?.data));
      return data;
    } else {
      dispatch({ type: actionTypes.SET_ERRORS, payload: data });
      toast.error("OTP Verification Failed");
    }
  } catch (error) {
    dispatch({ type: actionTypes.SET_CRASHES, payload: error });
    toast.error("An error occurred during OTP verification");
  } finally {
    setLoading(false);
  }
};

export const Logout = (navigate, setLoading) => async (dispatch) => {
  toast.success("Logging out...");
  localStorage.clear();
  dispatch({ type: actionTypes.LOG_OUT });
  window.location.reload();
};

export const fetchCustomerList = async(page, limit) => {
  const data = await api.getCustomers(page, limit);
  return data;
};

export const fetchSingleCustomer = async(id) => {
  const data = await api.getSingleCustomer(id);
  return data;
};