import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box, Drawer, IconButton, List, ListItem, ListItemButton,
  ListItemIcon, ListItemText, Collapse, useMediaQuery,
  Tooltip, Typography, Divider,
  AppBar,
  Toolbar
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import MailIcon from '@mui/icons-material/Mail';
import SettingsIcon from '@mui/icons-material/Settings';
import MovieIcon from '@mui/icons-material/Movie';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import HowToVoteIcon from '@mui/icons-material/HowToVote';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { useNavigate, useLocation } from 'react-router-dom';
import HandshakeIcon from '@mui/icons-material/Handshake';
import CelebrationIcon from '@mui/icons-material/Celebration';
import DownloadIcon from '@mui/icons-material/Download';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ImageIcon from '@mui/icons-material/Image';
import FilterIcon from '@mui/icons-material/Filter';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import InventoryIcon from '@mui/icons-material/Inventory';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import BuildIcon from '@mui/icons-material/Build';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import Logo from '../../assets/icons/Logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import TopNavigation from './TopNavigation';
import GroupIcon from '@mui/icons-material/Group';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import BusinessIcon from '@mui/icons-material/Business';
import StoreIcon from '@mui/icons-material/Store';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ListIcon from '@mui/icons-material/List';
import PersonIcon from '@mui/icons-material/Person';




// Menu structure with nested items and icons
const menuStructure = {
  dashboard: {
    id: 'dashboard',
    label: 'Dashboard',
    icon: <DashboardIcon />,
    path: '/dashboard'
  },
  rajOffset: {
    id: 'rajOffset',
    label: 'RajOffset',
    icon: <BusinessCenterIcon />, // Add an appropriate icon here
    children: [
      {
        id: 'digitalCard',
        label: 'Digital Card',
        icon: <CreditCardIcon />,
        children: [
          { label: 'Card Group', icon: <GroupWorkIcon />, path: '/cards/card-group' },
          { label: 'Card', icon: <CardGiftcardIcon />, path: '/cards/card-group/card' },
          { label: 'Card Entry', icon: <MailIcon />, path: '/cards/card-entry' }
        ]
      },
      {
        id: 'digitalInvite',
        label: 'Digital Invitation',
        icon: <MailIcon />,
        children: [
          { label: 'Digital Invite Card Entry', icon: <MailIcon />, path: '/digital-invite-card-value' }
        ]
      },
      {
        id: 'partnerDigitalCard',
        label: 'Partner Digital Card',
        icon: <HandshakeIcon />,
        children: [
          { label: 'Partner Card Group', icon: <GroupWorkIcon />, path: '/cards/partner-card-group' },
          { label: 'Partner Card', icon: <CardGiftcardIcon />, path: '/cards/partner-card-group/partner-card' },
          { label: 'Partner Card Entry', icon: <MailIcon />, path: '/cards/partner-card-entry' }
        ]
      },
      {
        id: 'bundle',
        label: 'Bundle',
        icon: <InventoryIcon />,
        children: [
          { label: 'Bundle Data', icon: <DataUsageIcon />, path: '/bundle-data' }
        ]
      }
    ]
  },
  common: {
    id: 'common',
    label: 'Common',
    icon: <SettingsIcon />,
    children: [
      {
        id: 'offers',
        label: 'Offers',
        icon: <LocalOfferIcon />,
        children: [
          { label: 'Diwali Offer', icon: <CelebrationIcon />, path: '/Diwali-offer' }
        ]
      },
      {
        id: 'quickUploader',
        label: 'Quick Uploader',
        icon: <CloudUploadIcon />,
        children: [
          {
            label: 'Simple Design Upload (Only One Image)',
            icon: <ImageIcon />,
            path: '/quick-uploader/direct-create'
          },
          {
            label: 'Foreground and Background Image Upload',
            icon: <FilterIcon />,
            path: '/quick-uploader/bg-fg-create'
          },
          {
            label: 'Political Image Upload (Only One Image)',
            icon: <HowToVoteIcon />,
            path: '/quick-uploader/political-direct-create'
          }
        ]
      },
      {
        id: 'videoEngine',
        label: 'Video Engine',
        icon: <MovieIcon />,
        children: [
          { label: 'All Video Templates', icon: <VideoLibraryIcon />, path: '/video-engine' }
        ]
      },
      {
        id: 'commonSettings',
        label: 'Common Settings',
        icon: <SettingsIcon />,
        children: [
          { label: 'Download', icon: <DownloadIcon />, path: '/common/download' },
          { label: 'Font Style', icon: <TextFieldsIcon />, path: '/font-style' }
        ]
      },
      {
        id: 'allTasks',
        label: 'All Tasks',
        icon: <AssignmentIcon />,
        children: [
          { label: 'All Tasks Overview', icon: <ListIcon />, path: '/all-tasks' }
        ]
      },
      {label: 'Design Category', icon: <ListIcon/>, path: '/design-category'}
    ]
  },

  netaji: {
    id: 'netaji',
    label: 'Netaji',
    icon: <GroupIcon />,
    children: [
      {
        id: 'politicalFrames',
        label: 'Political Frames',
        icon: <HowToVoteIcon />,
        children: [
          { label: 'Frame Group', icon: <GroupWorkIcon />, path: '/frame-group' },
          { label: 'Frame Template', icon: <CardGiftcardIcon />, path: '/frame-group/frame-template' },
          { label: 'Frame Entry', icon: <MailIcon />, path: '/frame-entry' },
        ],
      },
      {
        id: 'custom',
        label: 'Custom',
        icon: <BuildIcon />,
        children: [
          { label: 'Custom Group', icon: <GroupWorkIcon />, path: '/custom-group' },
          { label: 'Custom Template', icon: <DesignServicesIcon />, path: '/custom-template' },
        ],
      },
    ],
  },

  visitingCardLocker: {
    id: 'visitingCardLocker',
    label: 'Visiting Card Locker',
    icon: <CardMembershipIcon />,
    children: [
      {
        id: 'business',
        label: 'Business',
        icon: <BusinessIcon />,
        children: [
          { label: 'All Businesses', icon: <StoreIcon />, path: '/all-businesses' },
        ],
      },
    ],
  },
  account:{
    id: 'account',
    label: 'Account',
    icon: <SettingsIcon />,
    children: [
      { label: 'Deligate Access', icon: <SettingsIcon />, path: '/deligate-access' },
      { label: 'Customer', icon: <PersonIcon />, path: '/customer' },
    ],
  },

};

const drawerWidth = 260;

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'isMobile',
})(
  ({ theme, open, isMobile }) => ({
    width: 0,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiDrawer-paper': {
      position: 'fixed',
      width: drawerWidth,
      height: '100%',
      backgroundColor: '#1a237e',
      color: 'white',
      transition: theme.transitions.create(['width', 'transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: 'hidden',
      transform: open ? 'translateX(0)' : 'translateX(-100%)',
      visibility: open ? 'visible' : 'hidden',
      zIndex: theme.zIndex.drawer,
    },
  })
);

const StyledListItem = styled(ListItemButton)(({ theme, level = 0 }) => ({
  minHeight: 48,
  padding: '8px 16px',
  paddingLeft: theme.spacing(2 + level * 2),
  margin: '4px 8px',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  '&.Mui-selected': {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
  },
}));

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: 36,
  color: 'inherit',
  '& .MuiSvgIcon-root': {
    fontSize: '1.2rem',
  },
});

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiTypography-root': {
    fontSize: '0.9rem',
    fontWeight: 500,
  },
}));

const MenuItem = ({ item, open, level = 0, onClick, selected }) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    if (item.children) {
      setExpanded(!expanded);
    } else if (onClick) {
      onClick(item.path);
    }
  };

  return (
    <>
      <StyledListItem onClick={handleClick} level={level} selected={selected}>
        <Tooltip title={!open ? item.label : ''} placement="right">
          <StyledListItemIcon>{item.icon}</StyledListItemIcon>
        </Tooltip>
        {open && (
          <>
            <StyledListItemText primary={item.label} />
            {item.children && (
              <Box sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </Box>
            )}
          </>
        )}
      </StyledListItem>
      {item.children && (
        <Collapse in={expanded && open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.children.map((child) => (
              <MenuItem
                key={child.id || child.label}
                item={child}
                open={open}
                level={level + 1}
                onClick={onClick}
                selected={window.location.pathname === child.path}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  backgroundColor: '#0d47a1', // Slightly darker blue for header
  color: 'white',
  minHeight: 64,
}));

export default function MiniDrawer() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  // Handle drawer toggle
  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  // Close mobile drawer when route changes
  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Toggle Button - Always Visible */}

      <AppBar position="fixed" open={open}>
        <Toolbar sx={{
          justifyContent: 'space-between',
          ...(open && {
            paddingRight: '240px',
          }),
        }}>
          <IconButton
            color="gray"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
            {!open && (
              <Typography variant="h6" noWrap component="div" sx={{ paddingLeft: 0, color: '#003366', fontWeight: 'bold' }}>
                <img src={Logo} height={'50px'} alt="Logo" />
              </Typography>
            )}
          </IconButton>
          <TopNavigation open={open} />
        </Toolbar>
      </AppBar>

      <StyledDrawer
        variant="permanent"
        open={open}
        isMobile={isMobile}
        hideBackdrop={!isMobile}
        onClose={handleDrawerClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        ModalProps={{
          keepMounted: true,
          disableScrollLock: !isMobile,
          disablePortal: !isMobile,
        }}
      >
        <DrawerHeader>
          <Typography variant="h6" noWrap component="div" sx={{ paddingLeft: 0, color: '#003366', fontWeight: 'bold' }}>
            {open && <img src={Logo} height={'50px'} alt="Logo" />}
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>

        <Divider sx={{ borderColor: 'rgba(255, 255, 255, 0.12)' }} />

        <List sx={{ pt: 2 }}>
          {Object.entries(menuStructure).map(([section, sectionItems]) => (
            <MenuItem
              key={section}
              item={sectionItems}
              open={true}
              onClick={(path) => navigate(path)}
              selected={location.pathname === sectionItems.path}
            />
          ))}
        </List>
      </StyledDrawer>


      {/* Main content wrapper */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: '100%',
          marginLeft: 0,
        }}
      >
        {/* Your main content goes here */}
      </Box>
    </Box>
  );
}
