import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from "../components/navigation/Navigation";
import PublicRoutes from './publicRoutes';
import ProtectedRoutes from './protectedRoutes';
import AuthPage from '../view/authPage/AuthPage';
import Dashboard from '../view/dashboard/Dashboard';
import CardGroup from '../view/cardGroup/CardGroup';
import Cards from '../view/card/Cards';
import CardSample from '../view/cardSample/CardSample';
import CardFormField from '../view/card form field/CardFormField';
import CardEntry from '../view/card entry/CardEntry';
import CardEntryValues from '../view/card entry values/CardEntryValues';
import CardFormFieldOption from '../view/card form field options/CardFormFieldOption';
import Downloads from '../view/common/Downloads';
import SimpleDesignUpload from '../view/quick-uploader/SimpleDesignUpload';
import ForegroundBackgroundImageUpload from '../view/quick-uploader/ForegroundBackgroundImageUpload';
import PoliticalUpload from '../view/quick-uploader/PoliticalUpload';
import VideoEngineListTemplates from '../view/video_engine/video_engine_list_template/VideoEngineListTemplates';
import VideoEngineTemplateEdit from '../view/video_engine/video_engine_list_template/VideoEngineTemplateEdit';
import VideoEngineConfigurationEdit from '../view/video_engine/video_engine_list_template/VideoEngineConfigurationEdit';
import InvitationCard from '../view/card-attempt/InvitationCard';
import DiwaliOffer from '../view/diwali-offer/DiwaliOffer';
import FontStyle from '../view/common/FontStyle';
import BundleData from '../view/bundle/BundleData';
import PartnerCardGroup from '../view/partner-card-group/PartnerCardGroup';
import PartnerCards from '../view/partner-card/PartnerCards';
import PartnerCardSample from '../view/partner-card/PartnerCardSample';
import PartnerCardFormField from '../view/partner-card/PartnerCardFormField';
import PartnerCardEntry from '../view/partner-card/PartnerCardEntry';
import PartnerCardEntryValue from '../view/partner-card/PartnerCardEntryValue';
import PartnerCardFromFieldOption from '../view/partner-card/PartnerCardFromFieldOption';
import Loading from '../components/Loading';
import SuccessScreen from '../view/Submit-Page/SuccessScreen';
import DigitalInvitationCardEntry from '../view/digital-invite/DigitalInvitationCardEntry';
import ViewDigitalCard from '../view/digital-invite/ViewDigitalCard';
import FrameGroup from '../view/political-frame/FrameGroup';
import FrameTemplate from '../view/political-frame/FrameTemplate';
import FrameTemplateField from '../view/political-frame/FrameTemplateField';
import FrameEntry from '../view/political-frame/FrameEntry';
import CustomGroup from '../view/custom/CustomGroup';
import CustomTemplate from '../view/custom/CustomTemplate';
import FrameEntryValues from '../view/political-frame/FrameEntryValues';
import Business from '../view/visiting-card-locker/Business';
import EditMultiCardValue from '../view/bundle/EditMultiCardValue';
import AllTasks from '../view/dashboard/AllTasks';
import Accounts from '../components/Accounts';
import DeligateAccess from '../components/DeligateAccess';
import CustomerList from '../components/customers/CustomerList';
import CustomerDetailView from '../components/customers/CustomerDetailView';
import DesignCategory from '../view/design-category/DesignCategory';
import CategoryFields from '../view/design-category/CategoryFields';

const MainRouter = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route path='/auth' element={<AuthPage />} />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route path='/' element={<Navigation />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/cards/card-group" element={<CardGroup />} />
              <Route path="/cards/partner-card-group" element={<PartnerCardGroup />} />

              {/* Route for Cards with ID parameter */}
              <Route path="/cards/card-group/card" element={<Cards />} />
              <Route path="/cards/partner-card-group/partner-card" element={<PartnerCards />} />

              {/* Additional routes directly under /cards/card-group */}
              <Route path="/cards/card/card-attempt/:group_path/:id" element={<InvitationCard />} />
              <Route path="cards/card/card-sample/:card_id" element={<CardSample />} />
              <Route path="/cards/card/card-form-field/:card_id" element={<CardFormField />} />
              <Route path="/cards/card/card-form-field/:card_id/field-options/:child" element={<CardFormFieldOption />} />


              <Route path="cards/partner-card/partner-card-sample/:card_id" element={<PartnerCardSample />} />
              <Route path="/cards/partner-card/partner-card-form-field/:card_id" element={<PartnerCardFormField />} />
              <Route path="/cards/partner-card/partner-card-form-field/:card_id/field-options/:id" element={<PartnerCardFromFieldOption />} />


              {/* card-entry routes */}
              <Route path="/cards/card-entry" element={<CardEntry />} />
              <Route path="/cards/card-entry/card-entry-values/:child" element={<CardEntryValues />} />
              <Route path="/cards/card-entry/card-entry-values/:id" element={<CardEntryValues />} />

              <Route path="/cards/partner-card-entry" element={<PartnerCardEntry />} />
              <Route path="/cards/partner-card-entry/partner-card-entry-values/:id" element={<PartnerCardEntryValue />} />


              <Route path="/common">
                <Route path="download" element={<Downloads />} />
              </Route>
              <Route path="/quick-uploader">
                <Route path="direct-create" element={<SimpleDesignUpload />} />
                <Route path="bg-fg-create" element={<ForegroundBackgroundImageUpload />} />
                <Route path="political-direct-create" element={<PoliticalUpload />} />
              </Route>

              <Route path="video-engine"  >
                <Route path='' element={<VideoEngineListTemplates />} />
                <Route path='edit/:templateId' element={<VideoEngineTemplateEdit />} />
                <Route path='edit-config/:templateId' element={<VideoEngineConfigurationEdit />} />
              </Route>

              <Route path='Diwali-offer' element={<DiwaliOffer />} />
              <Route path='font-style' element={<FontStyle />} />
              <Route path="bundle-data" element={<BundleData />} />
              <Route path="loading" element={<Loading/>}/>
              <Route path="success-screen" element={<SuccessScreen/>}/>
              <Route path="digital-invite-card-value" element={<DigitalInvitationCardEntry/>}/>
              <Route path="view-digital-card/:id" element={<ViewDigitalCard/>}/>
              <Route path="frame-group" element={<FrameGroup/>}/>
              <Route path="/frame-group/frame-template" element={<FrameTemplate />} />
              <Route path="/frame-group/frame-template/frame-template-field/:frame_template_id" element={<FrameTemplateField />} />
              <Route path="/frame-entry" element={<FrameEntry/>}/>
              <Route path="/frame-entry/frame-entry-values/:id" element={<FrameEntryValues/>}/>

              <Route path="/custom-group" element={<CustomGroup/>}/>
              <Route path="/custom-template" element={<CustomTemplate/>}/>

              <Route path="all-businesses" element={<Business/>}/>
              <Route path="edit-multiCard-value/:id" element={<EditMultiCardValue/>}/>
              <Route path="/all-tasks" element={<AllTasks/>}/>
              <Route path='/accounts' element={<Accounts/>}/>
              <Route path='/deligate-access/:id' element={<DeligateAccess/>}/>
              <Route path="/customer" element={<CustomerList/>}/>
              <Route path='/customers/:id' element={<CustomerDetailView/>}/>
              <Route path='/design-category' element={<DesignCategory/>}/>
              <Route path='/design-category/category-fields/:id' element={<CategoryFields/>}/>
            </Route>
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default MainRouter;