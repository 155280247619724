export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const SET_CRASHES = "SET_CRASHES";
export const CLEAR_CRASHES = "CLEAR_CRASHES";

export const SHOW_OTP_INPUT = 'SHOW_OTP_INPUT';
export const LOG_IN = "LOG_IN";
export const REGISTER = "REGISTER";
export const LOG_OUT = "LOG_OUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const OTP_VERIFIED = "OTP_VERIFIED";

export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DETAILS_SUCCESS = 'FETCH_USER_DETAILS_SUCCESS';

export const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE';

export const ADD_CUSTOMER_REQUEST = 'ADD_CUSTOMER_REQUEST';
export const ADD_CUSTOMER_SUCCESS = 'ADD_CUSTOMER_SUCCESS';
export const ADD_CUSTOMER_FAILURE = 'ADD_CUSTOMER_FAILURE';

export const EDIT_CUSTOMER_REQUEST = 'EDIT_CUSTOMER_REQUEST';
export const EDIT_CUSTOMER_SUCCESS = 'EDIT_CUSTOMER_SUCCESS';
export const EDIT_CUSTOMER_FAILURE = 'EDIT_CUSTOMER_FAILURE';

export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE';

export const FETCH_ORDER_COUNT_REQUEST = 'FETCH_ORDER_COUNT_REQUEST';
export const FETCH_ORDER_COUNT_SUCCESS = 'FETCH_ORDER_COUNT_SUCCESS';
export const FETCH_ORDER_COUNT_FAILURE = 'FETCH_ORDER_COUNT_FAILURE';

export const FETCH_CARD_GROUP_REQUEST = 'FETCH_CARD_GROUP_REQUEST';
export const FETCH_CARD_GROUP_SUCCESS = 'FETCH_CARD_GROUP_SUCCESS';
export const FETCH_CARD_GROUP_FAILURE = 'FETCH_CARD_GROUP_FAILURE';

export const CREATE_CARD_GROUP_REQUEST = 'CREATE_CARD_GROUP_REQUEST';
export const CREATE_CARD_GROUP_SUCCESS = 'CREATE_CARD_GROUP_SUCCESS';
export const CREATE_CARD_GROUP_FAILURE = 'CREATE_CARD_GROUP_FAILURE';

export const EDIT_CARD_GROUP_REQUEST = 'EDIT_CARD_GROUP_REQUEST';
export const EDIT_CARD_GROUP_SUCCESS = 'EDIT_CARD_GROUP_SUCCESS';
export const EDIT_CARD_GROUP_FAILURE = 'EDIT_CARD_GROUP_FAILURE';

export const DELETE_CARD_GROUP_REQUEST = 'DELETE_CARD_GROUP_REQUEST';
export const DELETE_CARD_GROUP_SUCCESS = 'DELETE_CARD_GROUP_SUCCESS';
export const DELETE_CARD_GROUP_FAILURE = 'DELETE_CARD_GROUP_FAILURE';

export const FETCH_CARDS_REQUEST = 'FETCH_CARDS_REQUEST';
export const FETCH_CARDS_SUCCESS = 'FETCH_CARDS_SUCCESS';
export const FETCH_CARDS_FAILURE = 'FETCH_CARDS_FAILURE';

export const CREATE_CARDS_REQUEST = 'CREATE_CARDS_REQUEST';
export const CREATE_CARDS_SUCCESS = 'CREATE_CARDS_SUCCESS';
export const CREATE_CARDS_FAILURE = 'CREATE_CARDS_FAILURE';

export const UPDATE_CARDS_REQUEST = 'UPDATE_CARDS_REQUEST';
export const UPDATE_CARDS_SUCCESS = 'UPDATE_CARDS_SUCCESS';
export const UPDATE_CARDS_FAILURE = 'UPDATE_CARDS_FAILURE';

export const DELETE_CARDS_REQUEST = 'DELETE_CARDS_REQUEST';
export const DELETE_CARDS_SUCCESS = 'DELETE_CARDS_SUCCESS';
export const DELETE_CARDS_FAILURE = 'DELETE_CARDS_FAILURE';

export const FETCH_CARD_SAMPLE_REQUEST = 'FETCH_CARD_SAMPLE_REQUEST';
export const FETCH_CARD_SAMPLE_SUCCESS = 'FETCH_CARD_SAMPLE_SUCCESS';
export const FETCH_CARD_SAMPLE_FAILURE = 'FETCH_CARD_SAMPLE_FAILURE';

export const CREATE_CARD_SAMPLE_REQUEST = 'CREATE_CARD_SAMPLE_REQUEST';
export const CREATE_CARD_SAMPLE_SUCCESS = 'CREATE_CARD_SAMPLE_SUCCESS';
export const CREATE_CARD_SAMPLE_FAILURE = 'CREATE_CARD_SAMPLE_FAILURE';

export const UPDATE_CARD_SAMPLE_REQUEST = 'UPDATE_CARD_SAMPLE_REQUEST';
export const UPDATE_CARD_SAMPLE_SUCCESS = 'UPDATE_CARD_SAMPLE_SUCCESS';
export const UPDATE_CARD_SAMPLE_FAILURE = 'UPDATE_CARD_SAMPLE_FAILURE';

export const DELETE_CARD_SAMPLE_REQUEST = 'DELETE_CARD_SAMPLE_REQUEST';
export const DELETE_CARD_SAMPLE_SUCCESS = 'DELETE_CARD_SAMPLE_SUCCESS';
export const DELETE_CARD_SAMPLE_FAILURE = 'DELETE_CARD_SAMPLE_FAILURE';

export const GET_SINGLE_CARD_SAMPLE_REQUEST = 'GET_SINGLE_CARD_SAMPLE_REQUEST'
export const GET_SINGLE_CARD_SAMPLE_SUCCESS = 'GET_SINGLE_CARD_SAMPLE_SUCCESS'
export const GET_SINGLE_CARD_SAMPLE_FAILURE = 'GET_SINGLE_CARD_SAMPLE_FAILURE'

export const FETCH_CARD_FROM_FIELD_REQUEST = 'FETCH_CARD_FROM_FIELD_REQUEST';
export const FETCH_CARD_FROM_FIELD_SUCCESS = 'FETCH_CARD_FROM_FIELD_SUCCESS';
export const FETCH_CARD_FROM_FIELD_FAILURE = 'FETCH_CARD_FROM_FIELD_FAILURE';

export const CREATE_CARD_FROM_FIELD_REQUEST = 'CREATE_CARD_FROM_FIELD_REQUEST';
export const CREATE_CARD_FROM_FIELD_SUCCESS = 'CREATE_CARD_FROM_FIELD_SUCCESS';
export const CREATE_CARD_FROM_FIELD_FAILURE = 'CREATE_CARD_FROM_FIELD_FAILURE';

export const UPDATE_CARD_FROM_FIELD_REQUEST = 'UPDATE_CARD_FROM_FIELD_REQUEST';
export const UPDATE_CARD_FROM_FIELD_SUCCESS = 'UPDATE_CARD_FROM_FIELD_SUCCESS';
export const UPDATE_CARD_FROM_FIELD_FAILURE = 'UPDATE_CARD_FROM_FIELD_FAILURE';

export const DELETE_CARD_FROM_FIELD_REQUEST = 'DELETE_CARD_FROM_FIELD_REQUEST';
export const DELETE_CARD_FROM_FIELD_SUCCESS = 'DELETE_CARD_FROM_FIELD_SUCCESS';
export const DELETE_CARD_FROM_FIELD_FAILURE = 'DELETE_CARD_FROM_FIELD_FAILURE';

export const FETCH_CARD_FROM_FIELD_OPTIONS_REQUEST = 'FETCH_CARD_FROM_FIELD_OPTIONS_REQUEST';
export const FETCH_CARD_FROM_FIELD_OPTIONS_SUCCESS = 'FETCH_CARD_FROM_FIELD_OPTIONS_SUCCESS';
export const FETCH_CARD_FROM_FIELD_OPTIONS_FAILURE = 'FETCH_CARD_FROM_FIELD_OPTIONS_FAILURE';

export const CREATE_CARD_FROM_FIELD_OPTIONS_REQUEST = 'CREATE_CARD_FROM_FIELD_OPTIONS_REQUEST';
export const CREATE_CARD_FROM_FIELD_OPTIONS_SUCCESS = 'CREATE_CARD_FROM_FIELD_OPTIONS_SUCCESS';
export const CREATE_CARD_FROM_FIELD_OPTIONS_FAILURE = 'CREATE_CARD_FROM_FIELD_OPTIONS_FAILURE';

export const UPDATE_CARD_FROM_FIELD_OPTIONS_REQUEST = 'UPDATE_CARD_FROM_FIELD_OPTIONS_REQUEST';
export const UPDATE_CARD_FROM_FIELD_OPTIONS_SUCCESS = 'UPDATE_CARD_FROM_FIELD_OPTIONS_SUCCESS';
export const UPDATE_CARD_FROM_FIELD_OPTIONS_FAILURE = 'UPDATE_CARD_FROM_FIELD_OPTIONS_FAILURE';

export const DELETE_CARD_FROM_FIELD_OPTIONS_REQUEST = 'DELETE_CARD_FROM_FIELD_OPTIONS_REQUEST';
export const DELETE_CARD_FROM_FIELD_OPTIONS_SUCCESS = 'DELETE_CARD_FROM_FIELD_OPTIONS_SUCCESS';
export const DELETE_CARD_FROM_FIELD_OPTIONS_FAILURE = 'DELETE_CARD_FROM_FIELD_OPTIONS_FAILURE';

export const FETCH_CARD_ENTRY_REQUEST = 'FETCH_CARD_ENTRY_REQUEST';
export const FETCH_CARD_ENTRY_SUCCESS = 'FETCH_CARD_ENTRY_SUCCESS';
export const FETCH_CARD_ENTRY_FAILURE = 'FETCH_CARD_ENTRY_FAILURE';

export const CREATE_CARD_ENTRY_REQUEST = 'CREATE_CARD_ENTRY_REQUEST';
export const CREATE_CARD_ENTRY_SUCCESS = 'CREATE_CARD_ENTRY_SUCCESS';
export const CREATE_CARD_ENTRY_FAILURE = 'CREATE_CARD_ENTRY_FAILURE';

export const UPDATE_CARD_ENTRY_REQUEST = 'UPDATE_CARD_ENTRY_REQUEST';
export const UPDATE_CARD_ENTRY_SUCCESS = 'UPDATE_CARD_ENTRY_SUCCESS';
export const UPDATE_CARD_ENTRY_FAILURE = 'UPDATE_CARD_ENTRY_FAILURE';

export const DELETE_CARD_ENTRY_REQUEST = 'DELETE_CARD_ENTRY_REQUEST';
export const DELETE_CARD_ENTRY_SUCCESS = 'DELETE_CARD_ENTRY_SUCCESS';
export const DELETE_CARD_ENTRY_FAILURE = 'DELETE_CARD_ENTRY_FAILURE';

export const FETCH_CARD_ENTRY_VALUES_REQUEST = 'FETCH_CARD_ENTRY_VALUES_REQUEST';
export const FETCH_CARD_ENTRY_VALUES_SUCCESS = 'FETCH_CARD_ENTRY_VALUES_SUCCESS';
export const FETCH_CARD_ENTRY_VALUES_FAILURE = 'FETCH_CARD_ENTRY_VALUES_FAILURE';

export const CREATE_CARD_ENTRY_VALUES_REQUEST = 'CREATE_CARD_ENTRY_VALUES_REQUEST';
export const CREATE_CARD_ENTRY_VALUES_SUCCESS = 'CREATE_CARD_ENTRY_VALUES_SUCCESS';
export const CREATE_CARD_ENTRY_VALUES_FAILURE = 'CREATE_CARD_ENTRY_VALUES_FAILURE';

export const UPDATE_CARD_ENTRY_VALUES_REQUEST = 'UPDATE_CARD_ENTRY_VALUES_REQUEST';
export const UPDATE_CARD_ENTRY_VALUES_SUCCESS = 'UPDATE_CARD_ENTRY_VALUES_SUCCESS';
export const UPDATE_CARD_ENTRY_VALUES_FAILURE = 'UPDATE_CARD_ENTRY_VALUES_FAILURE';

export const DELETE_CARD_ENTRY_VALUES_REQUEST = 'DELETE_CARD_ENTRY_VALUES_REQUEST';
export const DELETE_CARD_ENTRY_VALUES_SUCCESS = 'DELETE_CARD_ENTRY_VALUES_SUCCESS';
export const DELETE_CARD_ENTRY_VALUES_FAILURE = 'DELETE_CARD_ENTRY_VALUES_FAILURE';

export const FETCH_DOWNLOAD_REQUEST = 'FETCH_DOWNLOAD_REQUEST';
export const FETCH_DOWNLOAD_SUCCESS = 'FETCH_DOWNLOAD_SUCCESS';
export const FETCH_DOWNLOAD_FAILURE = 'FETCH_DOWNLOAD_FAILURE';

export const FETCH_TEMPLATE_RECORD_REQUEST = 'FETCH_TEMPLATE_RECORD_REQUEST';
export const FETCH_TEMPLATE_RECORD_SUCCESS ='FETCH_TEMPLATE_RECORD_SUCCESS';
export const FETCH_TEMPLATE_RECORD_FAILURE = 'FETCH_TEMPLATE_RECORD_FAILURE';

export const SEND_TEMPLATE_ID_REQUEST = 'SEND_TEMPLATE_ID_REQUEST';
export const SEND_TEMPLATE_ID_SUCCESS = 'SEND_TEMPLATE_ID_SUCCESS';
export const SEND_TEMPLATE_ID_FAILURE = 'SEND_TEMPLATE_ID_FAILURE';

export const FETCH_CARDS_FOR_CUSTOMER_REQUEST = 'FETCH_CARDS_FOR_CUSTOMER_REQUEST';
export const FETCH_CARDS_FOR_CUSTOMER_SUCCESS = 'FETCH_CARDS_FOR_CUSTOMER_SUCCESS';
export const FETCH_CARDS_FOR_CUSTOMER_FAILURE = 'FETCH_CARDS_FOR_CUSTOMER_FAILURE';

export const CREATE_CARDS_FOR_CUSTOMER_REQUEST = 'CREATE_CARDS_FOR_CUSTOMER_REQUEST';
export const CREATE_CARDS_FOR_CUSTOMER_SUCCESS = 'CREATE_CARDS_FOR_CUSTOMER_SUCCESS';
export const CREATE_CARDS_FOR_CUSTOMER_FAILURE = 'CREATE_CARDS_FOR_CUSTOMER_FAILURE';

export const FETCH_BUNDLE_DATA_REQUEST = 'FETCH_BUNDLE_DATA_REQUEST';
export const FETCH_BUNDLE_DATA_SUCCESS = 'FETCH_BUNDLE_DATA_SUCCESS';
export const FETCH_BUNDLE_DATA_FAILURE = 'FETCH_BUNDLE_DATA_FAILURE';

export const FETCH_ALL_CARDS_DATA_REQUEST = 'FETCH_ALL_CARDS_DATA_REQUEST';
export const FETCH_ALL_CARDS_DATA_SUCCESS = 'FETCH_ALL_CARDS_DATA_SUCCESS';
export const FETCH_ALL_CARDS_DATA_FAILURE = 'FETCH_ALL_CARDS_DATA_FAILURE';

export const FETCH_PARTNER_CARD_GROUP_DATA_REQUEST = 'FETCH_PARTNER_CARD_GROUP_DATA_REQUEST';
export const FETCH_PARTNER_CARD_GROUP_DATA_SUCCESS = 'FETCH_PARTNER_CARD_GROUP_DATA_SUCCESS';
export const FETCH_PARTNER_CARD_GROUP_DATA_FAILURE = 'FETCH_PARTNER_CARD_GROUP_DATA_FAILURE';

export const UPDATE_PARTNER_CARD_GROUP_DATA_REQUEST = 'UPDATE_PARTNER_CARD_GROUP_DATA_REQUEST';
export const UPDATE_PARTNER_CARD_GROUP_DATA_SUCCESS = 'UPDATE_PARTNER_CARD_GROUP_DATA_SUCCESS';
export const UPDATE_PARTNER_CARD_GROUP_DATA_FAILURE = 'UPDATE_PARTNER_CARD_GROUP_DATA_FAILURE';

export const FETCH_PARTNER_CARDS_DATA_REQUEST = 'FETCH_PARTNER_CARDS_DATA_REQUEST';
export const FETCH_PARTNER_CARDS_DATA_SUCCESS = 'FETCH_PARTNER_CARDS_DATA_SUCCESS';
export const FETCH_PARTNER_CARDS_DATA_FAILURE = 'FETCH_PARTNER_CARDS_DATA_FAILURE';

export const FETCH_ALL_PARTNER_CARDS_DATA_REQUEST = 'FETCH_ALL_PARTNER_CARDS_DATA_REQUEST'
export const FETCH_ALL_PARTNER_CARDS_DATA_SUCCESS = 'FETCH_ALL_PARTNER_CARDS_DATA_SUCCESS'
export const FETCH_ALL_PARTNER_CARDS_DATA_FAILURE = 'FETCH_ALL_PARTNER_CARDS_DATA_FAILURE'

export const UPDATE_PARTNER_CARDS_DATA_REQUEST = 'UPDATE_PARTNER_CARDS_DATA_REQUEST';
export const UPDATE_PARTNER_CARDS_DATA_SUCCESS = 'UPDATE_PARTNER_CARDS_DATA_SUCCESS';
export const UPDATE_PARTNER_CARDS_DATA_FAILURE = 'UPDATE_PARTNER_CARDS_DATA_FAILURE';

export const FETCH_PARTNER_CARD_SAMPLE_DATA_REQUEST = 'FETCH_PARTNER_CARD_SAMPLE_DATA_REQUEST';
export const FETCH_PARTNER_CARD_SAMPLE_DATA_SUCCESS = 'FETCH_PARTNER_CARD_SAMPLE_DATA_SUCCESS';
export const FETCH_PARTNER_CARD_SAMPLE_DATA_FAILURE = 'FETCH_PARTNER_CARD_SAMPLE_DATA_FAILURE';

export const GET_SINGLE_PARTNER_CARD_SAMPLE_DATA_REQUEST = 'GET_SINGLE_PARTNER_CARD_SAMPLE_DATA_REQUEST';
export const GET_SINGLE_PARTNER_CARD_SAMPLE_DATA_SUCCESS = 'GET_SINGLE_PARTNER_CARD_SAMPLE_DATA_SUCCESS';
export const GET_SINGLE_PARTNER_CARD_SAMPLE_DATA_FAILURE = 'GET_SINGLE_PARTNER_CARD_SAMPLE_DATA_FAILURE';

export const CREATE_PARTNER_CARD_SAMPLE_REQUEST = 'CREATE_PARTNER_CARD_SAMPLE_REQUEST';
export const CREATE_PARTNER_CARD_SAMPLE_SUCCESS = 'CREATE_PARTNER_CARD_SAMPLE_SUCCESS';
export const CREATE_PARTNER_CARD_SAMPLE_FAILURE = 'CREATE_PARTNER_CARD_SAMPLE_FAILURE';

export const FETCH_PARTNER_CARD_FORM_FIELD_DATA_REQUEST = 'FETCH_PARTNER_CARD_FORM_FIELD_DATA_REQUEST';
export const FETCH_PARTNER_CARD_FORM_FIELD_DATA_SUCCESS = 'FETCH_PARTNER_CARD_FORM_FIELD_DATA_SUCCESS';
export const FETCH_PARTNER_CARD_FORM_FIELD_DATA_FAILURE = 'FETCH_PARTNER_CARD_FORM_FIELD_DATA_FAILURE';

export const UPDATE_PARTNER_CARD_SAMPLE_REQUEST = 'UPDATE_PARTNER_CARD_SAMPLE_REQUEST ';
export const UPDATE_PARTNER_CARD_SAMPLE_SUCCESS = 'UPDATE_PARTNER_CARD_SAMPLE_SUCCESS ';
export const UPDATE_PARTNER_CARD_SAMPLE_FAILURE = 'UPDATE_PARTNER_CARD_SAMPLE_FAILURE ';

export const UPDATE_PARTNER_CARD_FORM_FIELD_REQUEST = 'UPDATE_PARTNER_CARD_FORM_FIELD_REQUEST';
export const UPDATE_PARTNER_CARD_FORM_FIELD_SUCCESS = 'UPDATE_PARTNER_CARD_FORM_FIELD_SUCCESS';
export const UPDATE_PARTNER_CARD_FORM_FIELD_FAILURE = 'UPDATE_PARTNER_CARD_FORM_FIELD_FAILURE';

export const FETCH_PARTNER_CARD_ENTRY_DATA_REQUEST = 'FETCH_PARTNER_CARD_ENTRY_DATA_REQUEST'
export const FETCH_PARTNER_CARD_ENTRY_DATA_SUCCESS = 'FETCH_PARTNER_CARD_ENTRY_DATA_SUCCESS'
export const FETCH_PARTNER_CARD_ENTRY_DATA_FAILURE = 'FETCH_PARTNER_CARD_ENTRY_DATA_FAILURE'

export const FETCH_PAERTNER_CARD_ENTRY_VALUE_DATA_REQUEST = 'FETCH_PAERTNER_CARD_ENTRY_VALUE_DATA_REQUEST';
export const FETCH_PAERTNER_CARD_ENTRY_VALUE_DATA_SUCCESS = 'FETCH_PAERTNER_CARD_ENTRY_VALUE_DATA_SUCCESS';
export const FETCH_PAERTNER_CARD_ENTRY_VALUE_DATA_FAILURE = 'FETCH_PAERTNER_CARD_ENTRY_VALUE_DATA_FAILURE';

export const UPDATE_PARTNER_CARD_ENTRY_VALUE_REQUEST = 'UPDATE_PARTNER_CARD_ENTRY_VALUE_REQUEST';
export const UPDATE_PARTNER_CARD_ENTRY_VALUE_SUCCESS = 'UPDATE_PARTNER_CARD_ENTRY_VALUE_SUCCESS';
export const UPDATE_PARTNER_CARD_ENTRY_VALUE_FAILURE = 'UPDATE_PARTNER_CARD_ENTRY_VALUE_FAILURE';

export const DELETE_PARTNER_CARD_ENTRY_VALUE_REQUEST = 'DELETE_PARTNER_CARD_ENTRY_VALUE_REQUEST';
export const DELETE_PARTNER_CARD_ENTRY_VALUE_SUCCESS = 'DELETE_PARTNER_CARD_ENTRY_VALUE_SUCCESS';
export const DELETE_PARTNER_CARD_ENTRY_VALUE_FAILURE = 'DELETE_PARTNER_CARD_ENTRY_VALUE_FAILURE';

export const FETCH_PARTNER_CARD_FROM_FIELD_OPTION_REQUEST = 'FETCH_PARTNER_CARD_FROM_FIELD_OPTION_REQUEST';
export const FETCH_PARTNER_CARD_FROM_FIELD_OPTION_SUCCESS = 'FETCH_PARTNER_CARD_FROM_FIELD_OPTION_SUCCESS';
export const FETCH_PARTNER_CARD_FROM_FIELD_OPTION_FAILURE = 'FETCH_PARTNER_CARD_FROM_FIELD_OPTION_FAILURE';

export const GET_ALL_DIGITAL_INVITATION_CARD_ENTRY_REQUEST = 'GET_ALL_DIGITAL_INVITATION_CARD_ENTRY_REQUEST';
export const GET_ALL_DIGITAL_INVITATION_CARD_ENTRY_SUCCESS = 'GET_ALL_DIGITAL_INVITATION_CARD_ENTRY_SUCCESS';
export const GET_ALL_DIGITAL_INVITATION_CARD_ENTRY_FAILURE = 'GET_ALL_DIGITAL_INVITATION_CARD_ENTRY_FAILURE';

export const GET_ALL_DIGITAL_CARDS_REQUEST = 'GET_ALL_DIGITAL_CARDS_REQUEST';
export const GET_ALL_DIGITAL_CARDS_SUCCESS = 'GET_ALL_DIGITAL_CARDS_SUCCESS';
export const GET_ALL_DIGITAL_CARDS_FAILURE = 'GET_ALL_DIGITAL_CARDS_FAILURE';

export const GET_SINGLE_DIGITAL_INVITATION_CARD_ENTRY_REQUEST = 'GET_SINGLE_DIGITAL_INVITATION_CARD_ENTRY_REQUEST';
export const GET_SINGLE_DIGITAL_INVITATION_CARD_ENTRY_SUCCESS = 'GET_SINGLE_DIGITAL_INVITATION_CARD_ENTRY_SUCCESS';
export const GET_SINGLE_DIGITAL_INVITATION_CARD_ENTRY_FAILURE = 'GET_SINGLE_DIGITAL_INVITATION_CARD_ENTRY_FAILURE';

export const GET_ALL_FRAME_GROUP_REQUEST = 'GET_ALL_FRAME_GROUP_REQUEST';
export const GET_ALL_FRAME_GROUP_SUCCESS = 'GET_ALL_FRAME_GROUP_SUCCESS';
export const GET_ALL_FRAME_GROUP_FAILURE = 'GET_ALL_FRAME_GROUP_FAILURE';

export const GET_ALL_TEMPLATE_REQUEST = 'GET_ALL_TEMPLATE_REQUEST';
export const GET_ALL_TEMPLATE_SUCCESS = 'GET_ALL_TEMPLATE_SUCCESS';
export const GET_ALL_TEMPLATE_FAILURE = 'GET_ALL_TEMPLATE_FAILURE';

export const GET_TEMPLATE_FIELD_REQUEST = 'GET_TEMPLATE_FIELD_REQUEST';
export const GET_TEMPLATE_FIELD_SUCCESS = 'GET_TEMPLATE_FIELD_SUCCESS';
export const GET_TEMPLATE_FIELD_FAILURE = 'GET_TEMPLATE_FIELD_FAILURE';

export const GET_ALL_FRAME_ENTRY_REQUEST = 'GET_ALL_FRAME_ENTRY_REQUEST';
export const GET_ALL_FRAME_ENTRY_SUCCESS = 'GET_ALL_FRAME_ENTRY_SUCCESS';
export const GET_ALL_FRAME_ENTRY_FAILURE = 'GET_ALL_FRAME_ENTRY_FAILURE';

export const GET_ALL_CUSTOM_GROUP_REQUEST = 'GET_ALL_CUSTOM_GROUP_REQUEST';
export const GET_ALL_CUSTOM_GROUP_SUCCESS = 'GET_ALL_CUSTOM_GROUP_SUCCESS';
export const GET_ALL_CUSTOM_GROUP_FAILURE = 'GET_ALL_CUSTOM_GROUP_FAILURE';

export const GET_ALL_CUSTOM_TEMPLATES_REQUEST = 'GET_ALL_CUSTOM_TEMPLATES_REQUEST';
export const GET_ALL_CUSTOM_TEMPLATES_SUCCESS = 'GET_ALL_CUSTOM_TEMPLATES_SUCCESS';
export const GET_ALL_CUSTOM_TEMPLATES_FAILURE = 'GET_ALL_CUSTOM_TEMPLATES_FAILURE';

export const GET_FRAME_ENTRY_VALUE_REQUEST = 'GET_FRAME_ENTRY_VALUE_REQUEST';
export const GET_FRAME_ENTRY_VALUE_SUCCESS = 'GET_FRAME_ENTRY_VALUE_SUCCESS';
export const GET_FRAME_ENTRY_VALUE_FAILURE = 'GET_FRAME_ENTRY_VALUE_FAILURE';

export const GET_ALL_BUSINESSES_REQUEST = 'GET_ALL_BUSINESSES_REQUEST';
export const GET_ALL_BUSINESSES_SUCCESS = 'GET_ALL_BUSINESSES_SUCCESS';
export const GET_ALL_BUSINESSES_FAILURE = 'GET_ALL_BUSINESSES_FAILURE';

export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILURE = 'GET_ALL_USERS_FAILURE';

export const GET_ALL_TASKS_REQUEST = 'GET_ALL_TASKS_REQUEST';
export const GET_ALL_TASKS_SUCCESS = 'GET_ALL_TASKS_SUCCESS';
export const GET_ALL_TASKS_FAILURE = 'GET_ALL_TASKS_FAILURE';

export const GET_MULTI_CARD_VALUE_REQUEST = 'GET_MULTI_CARD_VALUE_REQUEST';
export const GET_MULTI_CARD_VALUE_SUCCESS = 'GET_MULTI_CARD_VALUE_SUCCESS';
export const GET_MULTI_CARD_VALUE_FAILURE = 'GET_MULTI_CARD_VALUE_FAILURE';



















