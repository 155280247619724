export const isAllowedToAccess = (permissionlist, permissionKey, targetRole) => {

    const checkRole = permissionlist.filter(role => role.key === permissionKey);

    let isAllowedFlag = false;
    let givenRole = checkRole[0]?.value
    switch (givenRole) {
        case 'H1':
            isAllowedFlag = ['H1', 'admin', 'manager', 'designer', 'developer', 'operator'].includes(targetRole)
            break;
        case 'admin':
            isAllowedFlag = ['admin', 'manager', 'designer', 'developer', 'operator'].includes(targetRole)
            break;
        case 'manager':
            isAllowedFlag = ['manager', 'designer', 'developer', 'operator'].includes(targetRole)
            break;
        case 'developer':
            isAllowedFlag = ['designer', 'developer', 'operator'].includes(targetRole)
            break;
        case 'designer':
            isAllowedFlag = ['designer', 'operator'].includes(targetRole)
            break;
        case 'operator':
            isAllowedFlag = ['operator'].includes(targetRole)
            break;


        default:
            break;
    }
    return isAllowedFlag;
};

