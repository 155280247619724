import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow,Paper,Typography,Box,Chip,Avatar,IconButton,Pagination,CircularProgress,Card,CardContent,useMediaQuery,useTheme,Tooltip} from '@mui/material';
import {Email,Phone,Verified,GppGood,CalendarToday,LocationOn,Star,Person,ContentCopy,MoreVert} from '@mui/icons-material';
import CustomerActionDialog from './CustomerActionDialog';
import { useNavigate } from 'react-router-dom';
import { fetchCustomerList } from '../../redux/actions/authActions';

const TENANT_COLORS = {
    netaji: '#95A5A6',  // Red shade
    rajoffset: '#4ECDC4', // Teal shade
    default: '#FFD700'  // light yellow for unknown tenants
};

const getTenantColor = (tenant) => {
    return TENANT_COLORS[tenant.toLowerCase()] || TENANT_COLORS.default;
};

const CustomerList = () => {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [error, setError] = useState('');
    const [copiedId, setCopiedId] = useState(null);
    const [copiedMobile, setCopiedMobile] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [isActionDialogOpen, setIsActionDialogOpen] = useState(false);
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    useEffect(() => {
        fetchCustomers(currentPage);
    }, [currentPage]);

    const fetchCustomers = async (page) => {
        try {
            setLoading(true);
            const response = await fetchCustomerList(page, 50);
            console.log('Fetched customers:', response);
            setCustomers(response?.data?.data?.Records);
            setTotalPages(Math.ceil(response?.data?.data?.Total/50 ));
        } catch (err) {
            setError('Failed to fetch customers');
            console.error('Error fetching customers:', err);
        } finally {
            setLoading(false);
        }
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false, // This will show time in 24-hour format
            timeZone: 'UTC' // Add this if you want to ensure UTC time display
        });
    };

    const TenantChip = ({ tenant }) => (
        <Chip 
            icon={<Person />} 
            label={tenant}
            size="small"
            style={{
                backgroundColor: getTenantColor(tenant),
                color: 'white',
            }}
        />
    );

    const handleCopyId = (id) => {
        navigator.clipboard.writeText(id).then(() => {
            setCopiedId(id);
            // Reset the "Copied" state after 2 seconds
            setTimeout(() => setCopiedId(null), 2000);
        });
    };

    const IdWithCopy = ({ id }) => (
        <Box 
            display="flex" 
            alignItems="center" 
            className="copyable"
            onClick={(e) => e.stopPropagation()}
        >
            <Tooltip 
                title={copiedId === id ? "Copied!" : "Copy ID"} 
                placement="top"
            >
                <Typography 
                    variant="body2" 
                    color="textSecondary"
                    onClick={() => handleCopyId(id)}
                    sx={{ 
                        cursor: 'pointer',
                        '&:hover': {
                            color: 'primary.main'
                        },
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    ID: {id.slice(0, 8)}...
                    <ContentCopy sx={{ ml: 0.5, fontSize: 14 }} />
                </Typography>
            </Tooltip>
        </Box>
    );

    const handleCopyMobile = (countryCode, mobile) => {
        const fullNumber = `${countryCode}${mobile}`;  // Concatenate without '+'
        navigator.clipboard.writeText(fullNumber).then(() => {
            setCopiedMobile(fullNumber);
            setTimeout(() => setCopiedMobile(null), 2000);
        });
    };

    const handleOpenActionDialog = (customer) => {
        setSelectedCustomer(customer);
        setIsActionDialogOpen(true);
    };

    const handleCloseActionDialog = () => {
        setSelectedCustomer(null);
        setIsActionDialogOpen(false);
    };

    const handleRowClick = (customerId, event) => {
        // Prevent row click if clicking on action button or copy elements
        if (event.target.closest('.MuiIconButton-root') || 
            event.target.closest('.copyable')) {
            return;
        }
        
        // Open in new window/tab
        window.open(`/customers/${customerId}`, '_blank');
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
                Customer List
            </Typography>

            {isMobile ? (
                // Mobile view - Card layout
                <Box>
                    {customers.map((customer) => (
                        <Card 
                            key={customer.id} 
                            sx={{ mb: 2, cursor: 'pointer' }}
                            onClick={(e) => handleRowClick(customer.id, e)}
                        >
                            <CardContent>
                                <Box display="flex" justifyContent="space-between" alignItems="flex-start">
                                    <Box display="flex" alignItems="center" mb={2}>
                                        <Avatar 
                                            src={customer.avatar} 
                                            sx={{ width: 40, height: 40, mr: 2 }}
                                        >
                                            {customer.firstName?.[0] || customer.mobile?.[0]}
                                        </Avatar>
                                        <Box>
                                            <Typography variant="subtitle1">
                                                {customer.firstName 
                                                    ? `${customer.firstName} ${customer.lastName}`
                                                    : '---'
                                                }
                                            </Typography>
                                            <IdWithCopy id={customer.id} />
                                        </Box>
                                    </Box>
                                    <IconButton 
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleOpenActionDialog(customer);
                                        }}
                                        size="small"
                                    >
                                        <MoreVert />
                                    </IconButton>
                                </Box>

                                <Box display="flex" alignItems="center" mb={1}>
                                    <Phone sx={{ mr: 1, fontSize: 20 }} />
                                    <Tooltip 
                                        title={copiedMobile === `${customer.countryCode}${customer.mobile}` 
                                            ? "Copied!" 
                                            : "Click to copy"
                                        }
                                        placement="top"
                                    >
                                        <Typography 
                                            variant="body2"
                                            className="copyable"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleCopyMobile(customer.countryCode, customer.mobile);
                                            }}
                                            sx={{ 
                                                cursor: 'pointer',
                                                '&:hover': {
                                                    color: 'primary.main'
                                                }
                                            }}
                                        >
                                            +{customer.countryCode} {customer.mobile}
                                            {customer.mobileVerified && 
                                                <Verified color="primary" sx={{ ml: 1, fontSize: 14 }} />
                                            }
                                        </Typography>
                                    </Tooltip>
                                </Box>

                                <Box display="flex" alignItems="center" mb={1}>
                                    <Email sx={{ mr: 1, fontSize: 20 }} />
                                    <Typography variant="body2">
                                        {customer.email || 'No email'}
                                        {customer.emailVerified && 
                                            <Verified color="primary" sx={{ ml: 1, fontSize: 16 }} />
                                        }
                                    </Typography>
                                </Box>

                                <Box display="flex" alignItems="center" mb={1}>
                                    <CalendarToday sx={{ mr: 1, fontSize: 20 }} />
                                    <Typography variant="body2">
                                        {formatDate(customer.createdAt)}
                                    </Typography>
                                </Box>

                                <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
                                    {customer.isPremiumCustomer && (
                                        <Chip 
                                            icon={<Star />} 
                                            label="Premium" 
                                            color="primary" 
                                            size="small" 
                                        />
                                    )}
                                    <Chip 
                                        icon={<LocationOn />} 
                                        label={customer.environment} 
                                        size="small" 
                                    />
                                    <TenantChip tenant={customer.tenant} />
                                </Box>
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            ) : (
                // Desktop view - Table layout
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Customer</TableCell>
                                <TableCell>Contact</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Created</TableCell>
                                <TableCell>Environment</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customers.map((customer) => (
                                <TableRow 
                                    key={customer.id} 
                                    onClick={(e) => handleRowClick(customer.id, e)}
                                    sx={{ 
                                        cursor: 'pointer',
                                        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
                                    }}
                                >
                                    <TableCell>
                                        <Box display="flex" alignItems="center">
                                            <Avatar 
                                                src={customer.avatar} 
                                                sx={{ mr: 2 }}
                                            >
                                                {customer.firstName?.[0] || customer.mobile?.[0]}
                                            </Avatar>
                                            <Box>
                                                <Typography variant="subtitle2">
                                                    {customer.firstName 
                                                        ? `${customer.firstName} ${customer.lastName}`
                                                        : '---'
                                                    }
                                                </Typography>
                                                <IdWithCopy id={customer.id} />
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box>
                                            <Box display="flex" alignItems="center">
                                                <Phone sx={{ mr: 1, fontSize: 16 }} />
                                                <Tooltip 
                                                    title={copiedMobile === `${customer.countryCode}${customer.mobile}` 
                                                        ? "Copied!" 
                                                        : "Click to copy"
                                                    }
                                                    placement="top"
                                                >
                                                    <Typography 
                                                        variant="body2"
                                                        className="copyable"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleCopyMobile(customer.countryCode, customer.mobile);
                                                        }}
                                                        sx={{ 
                                                            cursor: 'pointer',
                                                            '&:hover': {
                                                                color: 'primary.main'
                                                            }
                                                        }}
                                                    >
                                                        +{customer.countryCode} {customer.mobile}
                                                        {customer.mobileVerified && 
                                                            <Verified color="primary" sx={{ ml: 1, fontSize: 14 }} />
                                                        }
                                                    </Typography>
                                                </Tooltip>
                                            </Box>
                                            <Box display="flex" alignItems="center">
                                                <Email sx={{ mr: 1, fontSize: 16 }} />
                                                <Typography variant="body2">
                                                    {customer.email || 'No email'}
                                                    {customer.emailVerified && 
                                                        <Verified color="primary" sx={{ ml: 1, fontSize: 14 }} />
                                                    }
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box display="flex" flexDirection="column" gap={1}>
                                            {customer.isPremiumCustomer && (
                                                <Chip 
                                                    icon={<Star />} 
                                                    label="Premium" 
                                                    color="primary" 
                                                    size="small" 
                                                />
                                            )}
                                            <TenantChip tenant={customer.tenant} />
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        {formatDate(customer.createdAt)}
                                    </TableCell>
                                    <TableCell>
                                        <Chip 
                                            icon={<LocationOn />} 
                                            label={customer.environment} 
                                            size="small" 
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton 
                                            className="MuiIconButton-root"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleOpenActionDialog(customer);
                                            }}
                                            size="small"
                                        >
                                            <MoreVert />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <Box display="flex" justifyContent="center" mt={3}>
                <Pagination 
                    count={totalPages} 
                    page={currentPage + 1} 
                    onChange={(e, page) => setCurrentPage(page - 1)}
                    color="primary"
                />
            </Box>

            <CustomerActionDialog
                open={isActionDialogOpen}
                onClose={handleCloseActionDialog}
                customer={selectedCustomer}
            />
        </Box>
    );
};

export default CustomerList; 