import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import AuthReducer from "./authReducers";
import orderCountReducer from "./orderCountReducers";
import cardGroupReducer from "./cardGroupReducer";
import cardsReducer from "./cardsReducers";
import cardSampleReducer from "./cardSampleReducers";
import cardFromFieldReducer from "./cardFromFieldReducer";
import cardFromFieldOptionsReducer from "./cardFromFieldOptionReducers";
import cardEntryreducer from "./cardEntryReducers";
import cardEntryValueReducer from "./cardEntryValuesReducer";
import downloadReducer from "./downloadReducer";
import templateRecordReducer from "./quickUploaderReducers";
import cardForCustomerReducer from "./cardsForCustomerReducer";
import bundleDataReducer from "./bundleDataReducers";
import allCardsReducer from "./allCardsReducers";
import partnerCardGroupReducer from "./partnerCardGroupReducers";
import partnerCardsReducer from "./partnerCardsReducers";
import allPartnerCardsReducer from "./allPartnerCardsReducers";
import partnerCardSampleReducer from "./partnerCardSampleReducers";
import partnerCardFormFieldReducer from "./partnerCardFormFieldReducer";
import partnerCardEntryreducer from "./partnerCardEntryReducers";
import partnerCardEntryValueReducer from "./partnerCardEntryValuesReducers";
import partnerCardFormFieldOptionsReducer from "./partnerCardFormFieldOptionReducers";
import digitalInvitationCardEntryReducer from "./digitalInvitationCardEntryReducers";
import digitalCardReducer from "./digitalCardReducers";
import frameGroupReducer from "./frameGroupReducers";
import templateReducer from "./templateReducers";
import templateFieldReducer from "./templateFieldReducers";
import frameEntryReducer from "./frameEntryReducers";
import customGroupReducer from "./customGroupReducers";
import customTemplateReducer from "./customTemplateReducers";
import frameEntryValueReducer from "./frameEntryValueReducers";
import allBusinessesReducer from "./businessReducers";
import usersReducer from "./usersReducers";
import tasksReducer from "./tasksReducers";
import projectsReducer from './projectsReducer';
import multiCardValueReducer from "./multiCardValueReducers";

const persistConfig = {
    key: "root",
    storage,
};


const rootReducer = combineReducers({
    auth: AuthReducer,
    orderCount: orderCountReducer,
    cardGroup: cardGroupReducer,
    cards: cardsReducer,
    cardSample: cardSampleReducer,
    cardFromField: cardFromFieldReducer,
    cardFromFieldOptions: cardFromFieldOptionsReducer,
    cardEntry: cardEntryreducer,
    cardEntryValue: cardEntryValueReducer,
    download: downloadReducer,
    templateRecord: templateRecordReducer,
    cardForCustomer: cardForCustomerReducer,
    bundle: bundleDataReducer,
    allCards: allCardsReducer,
    partnerCardsGroup : partnerCardGroupReducer,
    partnerCards : partnerCardsReducer,
    allPartnerCards : allPartnerCardsReducer,
    partnerCardSample: partnerCardSampleReducer,
    partnerCardFormFields: partnerCardFormFieldReducer,
    partnerCardEntry: partnerCardEntryreducer,
    partnerCardEntryValue: partnerCardEntryValueReducer,
    partnerCardFormFieldOptions: partnerCardFormFieldOptionsReducer,
    digitalCardEntry: digitalInvitationCardEntryReducer,
    digitalCards: digitalCardReducer,
    frameGroup: frameGroupReducer,
    template: templateReducer,
    templateField: templateFieldReducer,
    frameEntry: frameEntryReducer,
    customGroup: customGroupReducer,
    customTemplate: customTemplateReducer,
    frameEntryValue: frameEntryValueReducer,
    allBusinesses: allBusinessesReducer,
    users: usersReducer,
    tasks: tasksReducer,
    projects: projectsReducer,
    multiCardValue: multiCardValueReducer,
});

export default persistReducer(persistConfig, rootReducer);