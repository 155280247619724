import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Dialog, DialogActions, DialogContent, Pagination, InputAdornment, DialogTitle, FormControl, IconButton, InputLabel, Menu, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography, Grid, Drawer, Collapse } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { fetchCardGroup } from '../../redux/actions/cardGroupActions'; // Import your action
import { createCardsData, deleteCardsData, fetchCardsData, sendDuplicateCardRequest, sendMapTemplateId, updateCardsData, updateCardVisibiltyData } from '../../redux/actions/cardsActions';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemIcon from '@mui/material/ListItemIcon';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AccountBalanceWalletRoundedIco from '@mui/icons-material/AccountBalanceWalletRounded';
import { fetchAllCardsData } from '../../redux/actions/allCardsActions';
import Loading from '../../components/Loading';
import { isAllowedToAccess } from '../../components/PermissionManager';
import GroupIcon from '@mui/icons-material/Group';
import TitleIcon from '@mui/icons-material/Title';
import ReorderIcon from '@mui/icons-material/Reorder';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DescriptionIcon from '@mui/icons-material/Description';
import StarIcon from '@mui/icons-material/Star';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FilterListIcon from '@mui/icons-material/FilterList';


function Cards() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const locationUrl = useLocation();
    const group_id = new URLSearchParams(locationUrl.search).get('group_id');
    // console.log("id in cards page", group_id);


    const cardGroupData = useSelector((state) => state?.cardGroup?.cardGroupData?.data?.rows || []);
    
    const [loading, setLoading] = useState(true);
    const [openFilter, setOpenFilter] = useState(false);
    const [cardsData, setCardsData] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenuClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setEditCardId(id);
        setDeleteCardId(id);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setEditCardId(null);
        setDeleteCardId(null);
    };

    // pagination 

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState('');
    const limit = 50;

    // useEffect(() => {
    //     const fetchData = async () => {
    //         // console.log("page", page);
    //         // console.log("search", search);
    //         // console.log("limit", limit);
    //         await fetchCardsData(page, limit, search);
    //     };
    //     fetchData();
    // }, [page, search]);

    const handlePageChange = async (event, value) => {
        console.log("value", value);
        setPage(value);
        if (group_id) {
            await dispatch(fetchCardsData(page, limit, search, group_id));
        } else {
            await dispatch(fetchAllCardsData(value, limit, search));
        }

    };


    useEffect(() => {
        const fetchCards = async () => {
            setLoading(true);
            await dispatch(fetchCardGroup());
            if (group_id) {
                console.log("step 1")
                let rsp = await dispatch(fetchCardsData(page, limit, search, group_id));
                console.log("rsp in cards", rsp);
                if (rsp) {
                    let total = rsp?.data?.total;
                    let pages = Math.ceil(total / Number(limit));
                    setCardsData(rsp?.data);
                    setTotalPages(pages);
                    setLoading(false);
                }

            } else {
                let rsp = await dispatch(fetchAllCardsData(page, limit, search));
                console.log("rsp in cards", rsp);
                if (rsp) {
                    let total = rsp?.data?.total;
                    let pages = Math.ceil(total / Number(limit));
                    setCardsData(rsp?.data);
                    setTotalPages(pages);
                    setLoading(false);
                }

            }

        };
        fetchCards();
    }, [dispatch]);

    // Separate State Variables
    const [filterByGroupId, setFilterByGroupId] = useState("");
    const [filterByname, setFilterByName] = useState("");
    const [filterByCardOrder, setFilterByCardOrder] = useState("");
    const [filterByOriginalPrice, setFilterByOriginalPrice] = useState("");
    const [filterBySalePrice, setFilterBySalePrice] = useState("");
    const [filterByStatus, setFilterByStatus] = useState("");
    const [filterByMappingTemplateId, setFilterByMappingTemplateId] = useState("");
    const [filterByCardRating, setFilterByCardRating] = useState("");
    const [filterByVisibility, setFilterByVisibility] = useState("");
    const [filterBydescription, setFilterByDescription] = useState("");
    const [filterByCardId, setFilterByCardId] = useState("");

    const showCardsByFilteredData = async () => {
        setLoading(true);
        const rsp = await dispatch(fetchAllCardsData(page, limit,filterByCardId,filterBydescription, filterByGroupId, filterByname, filterByCardOrder, filterByOriginalPrice,
            filterBySalePrice, filterByStatus, filterByMappingTemplateId, filterByCardRating, filterByVisibility
        ))
        if (rsp) {
            let total = rsp?.data?.total;
            let pages = Math.ceil(total / Number(limit));
            setCardsData(rsp?.data);
            setTotalPages(pages);
            setLoading(false);
        }
    }




    const columns = [
        { id: 'card-id', name: 'Card Id' },
        { id: 'name', name: 'Card Name' },
        { id: 'description', name: 'Card Description' },
        { id: 'cardMedia', name: 'Card Media' },
        { id: 'order', name: 'Card Order' },
        { id: 'original', name: 'Original Price' },
        { id: 'sale', name: 'Sale price' },
        { id: 'status', name: 'Card Status' },
        { id: 'actions', name: 'Actions' }
    ];

    // Add functionality
    const [open, setOpen] = useState(false);
    const [cardName, setCardName] = useState('');
    const [cardDescription, setCardDescription] = useState('');
    const [cardOrder, setCardOrder] = useState('');
    const [groupId, setGroupId] = useState('');
    const [originalPrice, setOriginalPrice] = useState(0);
    const [salePrice, setSalePrice] = useState(0);
    const [mappingTemplateId, setMappingTemplateId] = useState('');


    const functionAdd = () => {
        openPopup();
    };

    const closePopup = () => {
        setOpen(false);
    };

    const openPopup = () => {
        setOpen(true);
    };

    const [openAddGroup, setOpenAddGroup] = useState(false);
    const functionAddGroup = () => {
        openAddGroupPopup();
    }
    const openAddGroupPopup = () => {
        setOpenAddGroup(true);
    };
    const closeAddGroupPopup = () => {
        setOpenAddGroup(false);
    }



    const handleCardsSubmit = async (e) => {
        e.preventDefault();
        const newCards = {
            card_name: cardName,
            card_description: cardDescription,
            card_order: cardOrder,
            group_id: groupId,
            original_price: originalPrice,
            sale_price: salePrice,
            mapping_template_id: mappingTemplateId
        }
        const response = await dispatch(createCardsData(newCards));
        console.log("response in card submit", response);

        if (response?.status === 'success') {
            toast.success('Card submitted successfully!', {
                autoClose: 3000,
            });
        } else {
            toast.error('Failed to submitted card. Please try again.', {
                autoClose: 3000,
            });
        }

        await dispatch(fetchAllCardsData(page, limit, search));
        await dispatch(fetchCardsData(page, limit, search, group_id));
        closePopup();
        setCardName('');
        setCardDescription('');
        setCardOrder('');
        setGroupId('');
        setOriginalPrice('');
        setSalePrice('');
    }



    // Edit functionality
    const [editOpen, setEditOpen] = useState(false);
    const [editCardName, setEditCardName] = useState('');
    const [editCardDescription, setEditCardDescription] = useState('');
    const [editCardOrder, setEditCardOrder] = useState('');
    const [editGroupId, setEditGroupId] = useState('');
    const [editCardId, setEditCardId] = useState('');
    const [editOriginalPrice, setEditOriginalPrice] = useState('');
    const [editSalePrice, setEditSalePrice] = useState('');




    const functionEdit = useCallback((card) => {
        console.log("inside Edit", card);
        setEditCardId(card.id);
        setEditGroupId(card.group_id);
        setEditCardName(card.card_name);
        setEditCardDescription(card.card_description);
        setEditCardOrder(card.card_order);
        setEditOriginalPrice(card.original_price);
        setEditSalePrice(card.sale_price);
        setEditOpen(true);
    }, []);

    const closeEditPopup = () => {
        setEditOpen(false);
    };

    const handleCardsEditSubmit = async (e) => {
        e.preventDefault();
        const updatedCards = {
            id: editCardId, card_name: editCardName, card_description: editCardDescription,
            card_order: editCardOrder, group_id: editGroupId, original_price: editOriginalPrice,
            sale_price: editSalePrice,
        }
        const response = await dispatch(updateCardsData(updatedCards));
        console.log("response in edit", response);

        if (response?.payload?.status === 'success') {


            toast.success('Card updated successfully!', {
                autoClose: 3000, // Auto-close after 3 seconds
            });
        } else {
            toast.error('Failed to update card. Please try again.', {
                autoClose: 3000,
            });
        }

        await dispatch(fetchAllCardsData(page, limit, search));
        await dispatch(fetchCardsData(page, limit, search, group_id));
        closeEditPopup();
    };



    // Delete functionality
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteCardId, setDeleteCardId] = useState('');

    const functionDelete = useCallback((id) => {
        setDeleteCardId(id);
        setDeleteOpen(true);
    }, []);
    const closeDeletePopup = () => {
        setDeleteOpen(false);
    };
    const handleCardDelete = async () => {
        if (deleteCardId) {
            const response = await dispatch(deleteCardsData(deleteCardId));
            console.log("delted card response", response);
            if (response?.status === 200) {
                toast.success('Card Deleted successfully!', {
                    autoClose: 3000,
                });
            } else {
                toast.error('Failed to Deleted card. Please try again.', {
                    autoClose: 3000,
                });
            }
            await dispatch(fetchAllCardsData(page, limit, search));
            await dispatch(fetchCardsData(page, limit, search, group_id));
            closeDeletePopup();
        }
    };
    // Map template 
    const [mapTemplateOpen, setMapTemplateOpen] = useState(false);
    const [mapTemplateCardId, setMapTemplateCardId] = useState('');
    const [mapTemplateId, setMapTemplateId] = useState('');

    const functionMapTemplateOpen = useCallback((id) => {
        setMapTemplateCardId(id);
        setMapTemplateOpen(true);
    }, []);

    const functionMapTemplateClose = () => {
        setMapTemplateOpen(false);
    }

    const handleMapCardTemplete = async (e) => {

        e.preventDefault();
        const mapTemplateData = { card_id: mapTemplateCardId, template_id: mapTemplateId };
        await sendMapTemplateId(mapTemplateData);
        setMapTemplateOpen(false);
    };

    //public visibility
    const [publicVisibiltyOpen, setPublicVisibilityOpen] = useState(false);
    const [publicVisibilityId, setPublicVisibilityId] = useState('');
    const [publicVisibilty, setPublicVisibilty] = useState();

    const functionPublicVisibilityOpen = useCallback((id) => {
        setPublicVisibilityId(id);
        setPublicVisibilityOpen(true);
    }, []);
    const functionPublicVisibilityClose = () => {
        setPublicVisibilityOpen(false);
    };

    const handlePublicVisibilty = async (e) => {
        const data = { is_public: publicVisibilty, card_id: publicVisibilityId };
        await updateCardVisibiltyData(data);
        if (group_id) {
            await dispatch(fetchCardsData(page, limit, search, group_id));
        } else {
            await dispatch(fetchAllCardsData(page, limit, search));
        }

        setPublicVisibilityId('');
        functionPublicVisibilityClose();
        handleMenuClose();
    };


    // validation 
    const validateCreateFields = () => {
        return cardOrder !== '' && !isNaN(cardOrder) &&
            originalPrice !== '' && !isNaN(originalPrice) && originalPrice >= 0 &&
            salePrice !== '' && !isNaN(salePrice) && salePrice >= 0;
    };

    const handleCopyToClipboard = (id) => {
        navigator.clipboard.writeText(id)
            .then(() => {
                console.log('Copied to clipboard:', id);
                toast.success('Copied to clipboard!');
            })
            .catch((err) => {
                console.error('Failed to copy:', err);
            });
    };

    const functionDuplicate = async (cardId) => {
        try {
            await sendDuplicateCardRequest(cardId);
            toast.success('Card duplicated successfully!');
        } catch (error) {
            toast.error('Failed to duplicate card!');
            console.error('Error duplicating card:', error);
        }
    };


    //search functionality 
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredGroups, setFilteredGroups] = useState(cardGroupData);

    const handleKeyDown = (event) => {

        if (event.key === "Enter") {
            const filteredData = cardGroupData.filter((item) => item?.group_name?.toLowerCase()?.includes(searchTerm?.toLowerCase()));
            setFilteredGroups(filteredData);
        }
    };


    // const filteredData = cardGroupData.filter((item) => item?.group_name?.toLowerCase()?.includes(searchTerm?.toLowerCase()));
    // console.log("filtered data", filteredData);
    const [dropDownOpen, setDropDownOpen] = useState(false);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    }

    const handleOpen = () => {
        setDropDownOpen(true);
    }

    const handleClose = () => {
        if (groupId) {
            setDropDownOpen(false);
        }

    }

    const handleGroupIdChange = (e) => {
        setGroupId(e.target.value);
        setDropDownOpen(false);
    }


    const accessRole = useSelector((state) => state?.auth?.user?.user_info?.meta_data);
    return (
        <>

            {isAllowedToAccess(accessRole, 'mainwebsite_role', 'designer') ? (
                <Box >
                    <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: { xs: 'flex-start', md: 'space-between' }
                        }}>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                                flexDirection: { xs: 'column', md: 'row' } // Adjusts layout based on screen size
                            }}>
                                <Typography sx={{
                                    fontSize: { xs: '20px', sm: '24px', md: '28px' },
                                    mb: { xs: 2, md: 0 }
                                }}>
                                    Cards
                                </Typography>
                                {/* Uncomment and use the TextField if needed */}
                                {/* <TextField
                                    size="small"
                                    placeholder="Search"
                                    sx={{
                                        width: { xs: '100%', sm: '200px' },
                                        borderRadius: '20px',
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '20px'
                                        }
                                    }}
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SearchIcon
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={handleSearch}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                /> */}
                                <Box sx={{ position: 'relative' }}>
                                    <IconButton
                                        onClick={() => setOpenFilter(!openFilter)}
                                        sx={{ position: "relative", zIndex: 10 }} // Adjusted position to relative
                                    >
                                        <FilterListIcon />
                                    </IconButton>
                                    {/* Other content */}
                                </Box>
                            </Box>

                            <Box sx={{
                                display: 'flex',
                                gap: { xs: 1, md: 0.5 },
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: { xs: 'center', md: 'flex-end' },
                                width: { xs: '100%', md: 'auto' },
                                alignItems: 'center'
                            }}>

                                {isAllowedToAccess(accessRole, 'mainwebsite_role', 'manager') &&
                                    <Button
                                        variant='contained'
                                        sx={{
                                            backgroundColor: 'midnightblue',
                                            textTransform: 'none',
                                            fontSize: { xs: '12px', sm: '14px', md: '16px' },
                                            width: { xs: '100%', sm: 'auto' }
                                        }}
                                        onClick={functionAdd}
                                    >
                                        Add Card
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Toolbar>
                    <Collapse in={openFilter}>
                        <Paper elevation={3} sx={{ padding: 2, marginTop: 1 }}>
                            <Typography variant="h6">Filter</Typography>



                            <Grid container spacing={2} mt={1}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Card Group Id"
                                        value={filterByGroupId}
                                        onChange={(e) => setFilterByGroupId(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                     <GroupIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Card Name"
                                        value={filterByname}
                                        onChange={(e) => setFilterByName(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <TitleIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Card Order"
                                        value={filterByCardOrder}
                                        onChange={(e) => setFilterByCardOrder(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <ReorderIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Card Original Price"
                                        type='number'
                                        value={filterByOriginalPrice}
                                        onChange={(e) => setFilterByOriginalPrice(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AttachMoneyIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Card Sale Price"
                                        type='number'
                                        value={filterBySalePrice}
                                        onChange={(e) => setFilterBySalePrice(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LocalOfferIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Card Template Id"
                                        value={filterByMappingTemplateId}
                                        onChange={(e) => setFilterByMappingTemplateId(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <DescriptionIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Card Rating"
                                        value={filterByCardRating}
                                        onChange={(e) => setFilterByCardRating(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <StarIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Card Visibility"
                                        value={filterByVisibility}
                                        onChange={(e) => setFilterByVisibility(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <VisibilityIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Card Description"
                                        value={filterBydescription}
                                        onChange={(e) => setFilterByDescription(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <DescriptionIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Card Id"
                                        value={filterByCardId}
                                        onChange={(e) => setFilterByCardId(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CreditCardIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        fullWidth
                                        label="Card Status"
                                        value={filterByStatus}
                                        onChange={(e) => setFilterByStatus(e.target.value)}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <CheckCircleIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>



                                <Grid item xs={12} sm={6} md={3}>
                                    <Button variant="outlined" fullWidth onClick={showCardsByFilteredData}>
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Collapse>


                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow >
                                    {columns.map((column) => (
                                        <TableCell key={column.id}>{column.name}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={columns.length} align="center">
                                            <Loading />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <>
                                        {group_id ? (

                                            cardsData && cardsData?.rows?.map((card) => (
                                                <TableRow key={card.id}>
                                                    <TableCell
                                                        onClick={() => handleCopyToClipboard(card.id)} style={{ cursor: 'pointer' }}
                                                        sx={
                                                            card?.is_public
                                                                ? { borderLeft: '5px solid #558b2f' }
                                                                : { borderLeft: 'none' }
                                                        }
                                                    >
                                                        {card.id}
                                                    </TableCell>
                                                    <TableCell>
                                                        {card.card_name}
                                                        <p sx={{
                                                            color: 'gray',
                                                        }}>{card.card_description}</p>
                                                    </TableCell>
                                                    <TableCell>
                                                        {card?.group_detail?.group_name} - {card?.group_detail?.group_type || ""}
                                                        <p>
                                                            Path: {card?.group_detail?.group_path}
                                                        </p>
                                                    </TableCell>
                                                    <TableCell>
                                                        {/* {card?.card_samples?.[0]?.url && (
                                                    <img src={card.card_samples[0].url} alt="Card Sample" style={{ width: '150px', height: 'auto' }} />
                                                )} */}

                                                        {card?.card_samples?.[0]?.url && (() => {
                                                            const url = card.card_samples[0].url;
                                                            const extension = url.split('.').pop().toLowerCase();

                                                            if (['mp4', 'webm'].includes(extension)) {
                                                                return (
                                                                    <video width="150" height="auto" controls>
                                                                        <source src={url} type="video/mp4" />
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                );
                                                            } else if (['jpg', 'jpeg', 'png', 'webp'].includes(extension)) {
                                                                return (
                                                                    <img src={url} alt="Card Sample" style={{ width: '150px', height: 'auto' }} />
                                                                );
                                                            } else {
                                                                return null;
                                                            }
                                                        })()}
                                                    </TableCell>
                                                    <TableCell>{card.card_order}</TableCell>

                                                    <TableCell>{card.original_price}</TableCell>
                                                    <TableCell>{card.sale_price}</TableCell>
                                                    <TableCell>
                                                        {card.card_status}
                                                        <p>
                                                            {card.mapping_template_id}
                                                        </p>
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            aria-controls="simple-menu"
                                                            aria-haspopup="true"
                                                            onClick={(event) => handleMenuClick(event, card.id)}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>

                                                        <Menu
                                                            id="simple-menu"
                                                            anchorEl={anchorEl}
                                                            keepMounted
                                                            open={menuOpen && editCardId === card.id && deleteCardId === card.id}
                                                            onClose={handleMenuClose}
                                                        >
                                                            {isAllowedToAccess(accessRole, 'mainwebsite_role', 'manager') &&
                                                                <>
                                                                    <MenuItem onClick={() => functionEdit(card)}>
                                                                        <ListItemIcon>
                                                                            <EditIcon />
                                                                        </ListItemIcon>
                                                                        Edit
                                                                    </MenuItem>
                                                                    <MenuItem onClick={() => functionDuplicate(card.id)}>
                                                                        <ListItemIcon>
                                                                            <FileCopyIcon />
                                                                        </ListItemIcon>
                                                                        Duplicate
                                                                    </MenuItem>
                                                                    <MenuItem onClick={() => functionMapTemplateOpen(card.id)}>
                                                                        <ListItemIcon>
                                                                            <FormatListBulletedIcon />
                                                                        </ListItemIcon>
                                                                        Map Template
                                                                    </MenuItem>
                                                                    <MenuItem onClick={() => navigate(`/cards/card/card-sample/${card.id}`)}>
                                                                        <ListItemIcon>
                                                                            <VisibilityIcon />
                                                                        </ListItemIcon>
                                                                        Card Sample
                                                                    </MenuItem>
                                                                    <MenuItem onClick={() => navigate(`/cards/card/card-form-field/${card.id}`)}>
                                                                        <ListItemIcon>
                                                                            <FormatListBulletedIcon />
                                                                        </ListItemIcon>
                                                                        Card Form Field
                                                                    </MenuItem>
                                                                    <MenuItem onClick={() => navigate(`/cards/card/card-attempt/${card?.group_detail?.group_path}/${card.id}`)}>
                                                                        <ListItemIcon>
                                                                            <AccountBalanceWalletRoundedIco />
                                                                        </ListItemIcon>
                                                                        Card Attempt
                                                                    </MenuItem>
                                                                    <MenuItem onClick={() => functionPublicVisibilityOpen(card.id)}>
                                                                        <ListItemIcon>
                                                                            <VisibilityIcon />
                                                                        </ListItemIcon>
                                                                        Card Visibility
                                                                    </MenuItem>

                                                                </>
                                                            }

                                                            <br />
                                                            {isAllowedToAccess(accessRole, 'mainwebsite_role', 'admin') &&
                                                                <MenuItem onClick={() => functionDelete(card.id)} sx={{
                                                                    color: 'red'
                                                                }}>
                                                                    <ListItemIcon sx={{
                                                                        color: 'red'
                                                                    }}>
                                                                        <DeleteIcon />
                                                                    </ListItemIcon>
                                                                    Delete
                                                                </MenuItem>
                                                            }

                                                        </Menu>
                                                    </TableCell>
                                                </TableRow>
                                            ))

                                        ) : (
                                            cardsData && cardsData?.rows?.map((card, index) => (
                                                <TableRow key={index}>

                                                    <TableCell
                                                        onClick={() => handleCopyToClipboard(card.id)} style={{ cursor: 'pointer' }}
                                                        sx={
                                                            card?.is_public
                                                                ? { borderLeft: '5px solid #558b2f' }
                                                                : { borderLeft: 'none' }
                                                        }
                                                    >
                                                        {card.id}
                                                    </TableCell>
                                                    <TableCell>
                                                        {card.card_name}
                                                        <p sx={{
                                                            color: 'gray',
                                                        }}>{card.card_description}</p>
                                                    </TableCell>
                                                    <TableCell>
                                                        {card?.group_detail?.group_name} - {card?.group_detail?.group_type || ""}
                                                        <p>
                                                            Path: {card?.group_detail?.group_path}
                                                        </p>
                                                    </TableCell>
                                                    <TableCell>
                                                        {/* {card?.card_samples?.[0]?.url && (
                                                    <img src={card.card_samples[0].url} alt="Card Sample" style={{ width: '150px', height: 'auto' }} />
                                                )} */}

                                                        {card?.card_samples?.[0]?.url && (() => {
                                                            const url = card.card_samples[0].url;
                                                            const extension = url.split('.').pop().toLowerCase();

                                                            if (['mp4', 'webm'].includes(extension)) {
                                                                return (
                                                                    <video width="150" height="auto" controls>
                                                                        <source src={url} type="video/mp4" />
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                );
                                                            } else if (['jpg', 'jpeg', 'png', 'webp'].includes(extension)) {
                                                                return (
                                                                    <img src={url} alt="Card Sample" style={{ width: '150px', height: 'auto' }} />
                                                                );
                                                            } else {
                                                                return null;
                                                            }
                                                        })()}
                                                    </TableCell>
                                                    <TableCell>{card.card_order}</TableCell>

                                                    <TableCell>{card.original_price}</TableCell>
                                                    <TableCell>{card.sale_price}</TableCell>
                                                    <TableCell>
                                                        {card.card_status}
                                                        <p>
                                                            {card.mapping_template_id}
                                                        </p>
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            aria-controls="simple-menu"
                                                            aria-haspopup="true"
                                                            onClick={(event) => handleMenuClick(event, card.id)}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>

                                                        <Menu
                                                            id="simple-menu"
                                                            anchorEl={anchorEl}
                                                            keepMounted
                                                            open={menuOpen && editCardId === card.id && deleteCardId === card.id}
                                                            onClose={handleMenuClose}
                                                        >
                                                            <MenuItem onClick={() => functionEdit(card)}>
                                                                <ListItemIcon>
                                                                    <EditIcon />
                                                                </ListItemIcon>
                                                                Edit
                                                            </MenuItem>
                                                            <MenuItem onClick={() => functionDuplicate(card.id)}>
                                                                <ListItemIcon>
                                                                    <FileCopyIcon />
                                                                </ListItemIcon>
                                                                Duplicate
                                                            </MenuItem>
                                                            <MenuItem onClick={() => functionMapTemplateOpen(card.id)}>
                                                                <ListItemIcon>
                                                                    <FormatListBulletedIcon />
                                                                </ListItemIcon>
                                                                Map Template
                                                            </MenuItem>
                                                            <MenuItem onClick={() => navigate(`/cards/card/card-sample/${card.id}`)}>
                                                                <ListItemIcon>
                                                                    <VisibilityIcon />
                                                                </ListItemIcon>
                                                                Card Sample
                                                            </MenuItem>
                                                            <MenuItem onClick={() => navigate(`/cards/card/card-form-field/${card.id}`)}>
                                                                <ListItemIcon>
                                                                    <FormatListBulletedIcon />
                                                                </ListItemIcon>
                                                                Card Form Field
                                                            </MenuItem>
                                                            <MenuItem onClick={() => navigate(`/cards/card/card-attempt/${card?.group_detail?.group_path}/${card.id}`)}>
                                                                <ListItemIcon>
                                                                    <AccountBalanceWalletRoundedIco />
                                                                </ListItemIcon>
                                                                Card Attempt
                                                            </MenuItem>
                                                            <MenuItem onClick={() => functionPublicVisibilityOpen(card.id)}>
                                                                <ListItemIcon>
                                                                    <VisibilityIcon />
                                                                </ListItemIcon>
                                                                Card Visibility
                                                            </MenuItem>

                                                            <br />
                                                            <MenuItem onClick={() => functionDelete(card.id)} sx={{
                                                                color: 'red'
                                                            }}>
                                                                <ListItemIcon sx={{
                                                                    color: 'red'
                                                                }}>
                                                                    <DeleteIcon />
                                                                </ListItemIcon>
                                                                Delete
                                                            </MenuItem>
                                                        </Menu>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        )}
                                    </>


                                )}

                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1%' }}>
                        <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
                    </Box>
                </Box>
            ) : (
                <Typography>You don't have any permission</Typography>
            )}


            {/* Add Dialog */}


            <Dialog open={open} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Create Card</Typography>

                    </DialogTitle>
                    <IconButton onClick={closePopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleCardsSubmit}>
                        <Stack spacing={2} margin={2}>


                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Group Id</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Group Id"
                                    value={groupId}
                                    onChange={handleGroupIdChange}
                                    onOpen={handleOpen}
                                    onClose={handleClose}
                                    options={cardGroupData}
                                    isSearchable={true}

                                >
                                    {/* Search field inside dropdown */}
                                    <MenuItem >
                                        <TextField
                                            placeholder="Search Group"
                                            variant="outlined"
                                            fullWidth
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                            onKeyDown={handleKeyDown}
                                            onClick={(e) => e.stopPropagation()}
                                            autoFocus

                                        />
                                    </MenuItem>

                                    {/* Filtered dropdown items */}
                                    {filteredGroups.length > 0 ? (
                                        filteredGroups.map((cards) => (
                                            <MenuItem key={cards.id} value={cards.id}>
                                                {cards.group_name}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem></MenuItem>
                                    )}
                                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <MenuItem>
                                            <Button variant='outlined' onClick={functionAddGroup} fullWidth>Add Group</Button>
                                        </MenuItem>
                                        <MenuItem>
                                            <Button variant='outlined' fullWidth>Add Group</Button>
                                        </MenuItem>
                                    </Box>

                                </Select>
                            </FormControl>

                            <TextField
                                required
                                value={cardName}
                                onChange={e => setCardName(e.target.value)}
                                variant='outlined'
                                label="Card Name"
                            />
                            <TextField
                                variant='outlined'
                                value={cardDescription}
                                onChange={e => setCardDescription(e.target.value)}
                                label="Card Description"
                            />
                            <TextField
                                variant='outlined'
                                value={mappingTemplateId}
                                onChange={e => setMappingTemplateId(e.target.value)}
                                label="Mapping Template Id (Engine)"
                            />
                            <TextField
                                type='number'
                                required
                                variant='outlined'
                                value={cardOrder}
                                onChange={e => setCardOrder(e.target.value)}
                                label="Card Order"
                            />
                            <TextField
                                type='number'
                                required
                                variant='outlined'
                                value={originalPrice}
                                onChange={e => setOriginalPrice(e.target.value)}
                                label="Original Price"
                            />
                            <TextField
                                type='number'
                                required
                                variant='outlined'
                                value={salePrice}
                                onChange={e => setSalePrice(e.target.value)}
                                label="Sale Price"
                            />

                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button
                                    variant='outlined'
                                    sx={{ textTransform: 'none' }}
                                    onClick={closePopup}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant='contained'
                                    sx={{ textTransform: 'none' }}
                                    disabled={!validateCreateFields()}
                                    type='submit'
                                >
                                    Submit
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>


            {/* Edit Dialog */}
            <Dialog open={editOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Edit Card</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeEditPopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleCardsEditSubmit}>
                        <Stack spacing={2} margin={2}>
                            <FormControl>
                                <InputLabel id="demo-simple-select-label">Group Id</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Group Id"
                                    value={editGroupId}
                                    onChange={e => setEditGroupId(e.target.value)}
                                >
                                    {cardGroupData && cardGroupData?.length > 0 ? (
                                        cardGroupData?.map((cards) => (
                                            <MenuItem key={cards.id} value={cards.id}>{cards.group_name}</MenuItem>
                                        ))
                                    ) : (
                                        <Typography variant="body1" color="initial"></Typography>
                                    )}
                                </Select>
                            </FormControl>
                            <TextField required variant='outlined' value={editCardName} onChange={e => setEditCardName(e.target.value)} label="Card Name" />
                            <TextField variant='outlined' value={editCardDescription} onChange={e => setEditCardDescription(e.target.value)} label="Card Description" />
                            <TextField variant='outlined' type='number' value={editCardOrder} onChange={e => setEditCardOrder(e.target.value)} label="Card Order" />
                            <TextField variant='outlined' type='number' value={editOriginalPrice} onChange={e => setEditOriginalPrice(e.target.value)} label="Original Price" />
                            <TextField variant='outlined' type='number' value={editSalePrice} onChange={e => setEditSalePrice(e.target.value)} label="Sale Price" />
                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeEditPopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Update</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Delete Dialog */}
            <Dialog open={deleteOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Delete Card</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeDeletePopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent sx={{ marginTop: 4 }}>
                    <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                        Are you sure you want to delete this card?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button variant="outlined" onClick={closeDeletePopup}>Cancel</Button>
                    <Button variant="contained" color="error" onClick={handleCardDelete}>Delete</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={mapTemplateOpen} onClose={functionMapTemplateClose} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Map Template</Typography>
                    </DialogTitle>
                    <IconButton onClick={functionMapTemplateClose}>
                        <CloseIcon />
                    </IconButton>

                </Box>
                <DialogContent>
                    <TextField required fullWidth variant='outlined' label="Template Id" value={mapTemplateId} onChange={(e) => setMapTemplateId(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleMapCardTemplete}>Submit</Button>
                </DialogActions>
            </Dialog>
            {/*Add Group*/}
            <Dialog open={openAddGroup} onClose={closeAddGroupPopup}>
                <DialogTitle>Add Group</DialogTitle>

            </Dialog>

            <Dialog open={publicVisibiltyOpen} onClose={functionPublicVisibilityClose} fullWidth>
                <DialogTitle>Set Public Visibility</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth>
                        <InputLabel>Visibility</InputLabel>
                        <Select
                            value={publicVisibilty}
                            onChange={(e) => setPublicVisibilty(e.target.value)}
                            label="Visibility"
                        >
                            <MenuItem value={true}>True</MenuItem>
                            <MenuItem value={false}>False</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={functionPublicVisibilityClose}>Cancel</Button>
                    <Button onClick={handlePublicVisibilty} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default Cards;
