import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const fetchCardEntryRequest = () => ({
    type: actionTypes.FETCH_CARD_ENTRY_REQUEST,
});
export const fetchCardEntrySuccess = (data) => ({
    type: actionTypes.FETCH_CARD_ENTRY_SUCCESS,
    payload: data,
});
export const fetchCardEntryFailure = (error) => ({
    type: actionTypes.FETCH_CARD_ENTRY_FAILURE,
    payload: error,
});

export const fetchCardEntryData = (page, limit,id='',card_id='',user_id='',title='', status='',payment_status='',actual_paid_amount='',coupon_code='',mobile_number='',source='',bundle_id='', downloadRefId='') => {

    return async (dispatch) => {
        dispatch(fetchCardEntryRequest());
        try {
            const {data} = await api.cardEntry(page, limit,id,card_id,user_id,title, status,payment_status,actual_paid_amount,coupon_code,mobile_number,source,bundle_id, downloadRefId);
            dispatch(fetchCardEntrySuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchCardEntryFailure(error));
        }
    };
};

export const createCardEntryRequest = () => ({
    type: actionTypes.CREATE_CARD_ENTRY_REQUEST,
});
export const createCardEntrySuccess = (data) => ({
    type: actionTypes.CREATE_CARD_ENTRY_SUCCESS,
    payload: data
});
export const createCardEntryFailure = (error) => ({
    type: actionTypes.CREATE_CARD_ENTRY_FAILURE,
    payload: error,
});

export const createCardEntyrData = (newCardData) => {
    return async (dispatch) => {
        dispatch(createCardEntryRequest());
        try{
            const {data} = await api.createCardEntry(newCardData);
            dispatch(createCardEntrySuccess(data));
            return data;
        }catch(error) {
            dispatch(createCardEntryFailure(error));
        }
    }
};

export const updateCardEntryRequest = () => ({
    type: actionTypes.UPDATE_CARD_ENTRY_REQUEST,
});
export const updateCardEntrySuccess = (data) => ({
    type: actionTypes.UPDATE_CARD_ENTRY_SUCCESS,
    payload: data,
});
export const updateCardEntryFailure = (error) => ({
    type: actionTypes.UPDATE_CARD_ENTRY_FAILURE,
    payload: error,
});

export const updateCardEntryData = (updateData) => {
    return async (dispatch) => {
        dispatch(updateCardEntryRequest());
        try{
            const response = await api.updateCardEntry(updateData.id, updateData);
            const updatedCard = response?.data;
            const successAction = updateCardEntrySuccess(updatedCard);
            dispatch(successAction);
            return successAction;

        } catch(error) {
            const failureAction = updateCardEntryFailure(error.message);
            dispatch(failureAction);
            return failureAction;
        }
    };
};

export const deleteCardEntryRequest = () => ({
    type: actionTypes.DELETE_CARD_ENTRY_REQUEST,
});
export const deleteCardEntrySuccess = (id) => ({
    type: actionTypes.DELETE_CARD_ENTRY_SUCCESS,
    payload: id,
});
export const deleteCardEntryFailure = (error) => ({
    type: actionTypes.DELETE_CARD_ENTRY_FAILURE,
    payload: error,
});

export const deleteCardEntryData = (id) => {
    return async (dispatch) => {
        dispatch(deleteCardEntryRequest());
        try{
            const data = await api.deleteCardEntry(id);
            dispatch(deleteCardEntrySuccess(id));
            return data;
        } catch(error) {
            dispatch(deleteCardEntryFailure(error));
        }
    };
};
