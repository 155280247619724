import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const getMultiCardValueDataRequest = () => ({
    type: actionTypes.GET_MULTI_CARD_VALUE_REQUEST,
});
export const getMultiCardValueDataSuccess = (data) => ({
    type: actionTypes.GET_MULTI_CARD_VALUE_SUCCESS,
    payload: data,
});
export const getMultiCardValueDataFailure = (error) => ({
    type: actionTypes.GET_MULTI_CARD_VALUE_FAILURE,
    payload: error,
});

export const getMultiCardValueData = (id) => {
    

    return async (dispatch) => {
        
        dispatch(getMultiCardValueDataRequest());
        try {
            const {data} = await api.getMultiCardValue(id);
            dispatch(getMultiCardValueDataSuccess(data));
            return data;
        } catch (error) {
            dispatch(getMultiCardValueDataFailure(error));
        }
    };
};

export const editMultiCardValueData = async (formData) => {
    const data = await api.editMultiCardValue(formData?.id, formData);
    return data;
}





