import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, Divider, IconButton, Grid } from '@mui/material';
import { Close, Block, VerifiedUser, Edit, Delete, Email, WhatsApp, Phone, Star, StarBorder, PersonAdd } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';



const CustomerActionDialog = ({ open, onClose, customer }) => {
    const navigate = useNavigate();


    if (!customer) return null;

    const handleAction = (actionType) => {
        console.log(`Performing ${actionType} action for customer:`, customer.id);
        // Implement your action logic here
    };
    

    const ActionButton = ({ icon, label, color, onClick }) => (
        <Button
            variant="outlined"
            color={color || "primary"}
            startIcon={icon}
            onClick={onClick}
            fullWidth
            sx={{ mb: 1 }}
        >
            {label}
        </Button>
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Customer Actions</Typography>
                    <IconButton onClick={onClose} size="small">
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>

            <Divider />

            <DialogContent>
                {/* Customer Info Section */}
                <Box mb={3}>
                    <Typography variant="subtitle1" gutterBottom>
                        Customer Details
                    </Typography>
                    <Typography variant="body2">
                        ID: {customer.id}
                    </Typography>
                    <Typography variant="body2">
                        Mobile: +{customer.countryCode} {customer.mobile}
                    </Typography>
                    <Typography variant="body2">
                        Email: {customer.email || 'Not provided'}
                    </Typography>
                </Box>

                <Divider sx={{ mb: 3 }} />

                {/* Actions Grid */}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <ActionButton
                            icon={<Edit />}
                            label="Edit Customer"
                            onClick={() => handleAction('edit')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {customer.isPremiumCustomer ? (
                            <ActionButton
                                icon={<StarBorder />}
                                label="Remove Premium"
                                color="warning"
                                onClick={() => handleAction('removePremium')}
                            />
                        ) : (
                            <ActionButton
                                icon={<Star />}
                                label="Make Premium"
                                onClick={() => handleAction('makePremium')}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ActionButton
                            icon={<WhatsApp />}
                            label="WhatsApp Message"
                            onClick={() => handleAction('whatsapp')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ActionButton
                            icon={<Email />}
                            label="Send Email"
                            onClick={() => handleAction('email')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {customer.isVerificationReq ? (
                            <ActionButton
                                icon={<VerifiedUser />}
                                label="Verify Account"
                                color="success"
                                onClick={() => handleAction('verify')}
                            />
                        ) : (
                            <ActionButton
                                icon={<Block />}
                                label="Block Account"
                                color="error"
                                onClick={() => handleAction('block')}
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ActionButton
                            icon={<Delete />}
                            label="Delete Account"
                            color="error"
                            onClick={() => handleAction('delete')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <ActionButton
                            icon={<PersonAdd />}
                            label="Deligate Access"
                            onClick={() => navigate(`/deligate-access/${customer?.mobile}`)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CustomerActionDialog; 