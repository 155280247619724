import * as actionTypes from '../actionTypes.js';

const initialState = {
    multiCardValue: null,
    loading: false,
    error: null,
};

const multiCardValueReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MULTI_CARD_VALUE_REQUEST:
            return{
                ...state,
                loading: true,
                error: null,
            };
        case actionTypes.GET_MULTI_CARD_VALUE_SUCCESS:
            return{
                ...state,
                loading: false,
                multiCardValue: action.payload,
            };
        case actionTypes.GET_MULTI_CARD_VALUE_FAILURE:
            return{
                ...state,
                loading: false,
                error: action.payload, 
            }
        default:
            return state;
    }
};

export default multiCardValueReducer;