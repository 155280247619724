import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { fetchCardEntryData } from '../../redux/actions/cardEntryActions';
import { createCardEntryValueData, deleteCardEntryValueData, fetchCardEntryValueData, updateCardEntryValueData } from '../../redux/actions/cardEntryValuesActions';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { isAllowedToAccess } from '../../components/PermissionManager';
import { useParams } from 'react-router-dom';
import { addCategoryFieldsData, deleteCategoryFieldData, editCategoryFieldData, getCategoryFields } from '../../redux/actions/designCategoryActions';

function CategoryFields() {
    const dispatch = useDispatch();

    const cardEntry = useSelector((state) => state?.cardEntry?.cardEntryData?.data);
    const cardEntryValue = useSelector((state) => state?.cardEntryValue?.cardEntryValueData?.data);
    const [loading, setLoading] = useState(true);
    const params = useParams();
    console.log("params", params);

    const [categoryFielsData, setCategoryFieldsData] = useState([]);


    // Fetch card entry value data on component mount
    useEffect(() => {
        const fetchCategoryFields = async () => {
            setLoading(true);
            const response = await getCategoryFields(params?.id);
            console.log("category field", response);
            if (response) {
                setCategoryFieldsData(response?.data?.data);
            }
            setLoading(false);
        };

        fetchCategoryFields();
    }, [params]);

    const columns = [
        { id: 'entryId', name: 'Id' },
        { id: 'fieldName', name: 'Field Name' },
        { id: 'fieldValue', name: 'Field Value' },
        { id: 'type', name: 'Type' },
        { id: 'actions', name: 'Actions' }
    ];

    // Add functionality
    const [open, setOpen] = useState(false);
    const [fieldName, setFieldName] = useState('');
    const [fieldValue, setFieldValue] = useState('');
    const [fieldType, setFieldType] = useState('');

    const functionAddCategoryField = () => {
        setOpen(true);
    };

    const closeAddCategoryFieldPopup = () => {
        setOpen(false);
    };

    const handleCategoryFieldValueSubmit = async (e) => {
        e.preventDefault();
        const newEntryValue = {
            id: params.id, fieldName, fieldValue,
            type: fieldType
        }
        const response = await addCategoryFieldsData(newEntryValue);
        // if (response?.status === 'success') {
        //     toast.success('Card Entry Value submitted successfully!', {
        //         autoClose: 3000,
        //     });
        // } else {
        //     toast.error('Failed to submitted card Entry Value. Please try again.', {
        //         autoClose: 3000,
        //     });
        // }
        await getCategoryFields(params?.id);
        setFieldName();
        setFieldValue();
        setFieldType();
        closeAddCategoryFieldPopup();
        window.location.reload();
    }

    // Edit functionality
    const [editOpen, setEditOpen] = useState(false);
    const [editCategoryFieldId, setEditCategoryFieldId] = useState('');
    const [editCategoryId, setEditCategoryId] = useState('');
    const [editFieldName, setEditFieldName] = useState('');
    const [editFieldValue, setEditFieldValue] = useState('');
    const [editFieldType, setEditFieldType] = useState('');

    const functionEdit = useCallback((field) => {
        console.log("inside Edit", field);
        setEditCategoryFieldId(field?.id)
        setEditCategoryId(field?.categoryId);
        setEditFieldName(field?.fieldName);
        setEditFieldValue(field?.fieldValue);
        setEditFieldType(field?.type);

        setEditOpen(true);
    }, []);

    const closeEditPopup = () => {
        setEditOpen(false);
    };

    const handleCategoryFieldValueEditSubmit = async (e) => {
        e.preventDefault();
        const updatedCards = {
            id: params.id, field_id: editCategoryFieldId, fieldName: editFieldName,
            fieldValue: editFieldValue, type: editFieldType
        }
        const response = await editCategoryFieldData(updatedCards);
        // if (response?.payload?.status === 'success') {


        //     toast.success('Card Entry Value updated successfully!', {
        //         autoClose: 3000, // Auto-close after 3 seconds
        //     });
        // } else {
        //     toast.error('Failed to update Card Entry Value. Please try again.', {
        //         autoClose: 3000,
        //     });
        // }
        await getCategoryFields(params?.id);
        window.location.reload();
        closeEditPopup();
    };



    // Delete functionality
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteCategoryFieldId, setDeleteCategoryFieldId] = useState('');

    const functionDelete = useCallback((id) => {
        setDeleteCategoryFieldId(id);
        setDeleteOpen(true);
    }, []);
    const closeDeletePopup = () => {
        setDeleteOpen(false);
    };
    const handleCategoryFieldValueDelete = async () => {

        const deletedData = { id: params?.id, field_id: deleteCategoryFieldId }

        const response = await deleteCategoryFieldData(deletedData);
        // if (response?.status === 200) {
        //     toast.success('Card Deleted successfully!', {
        //         autoClose: 3000,
        //     });
        // } else {
        //     toast.error('Failed to Deleted card. Please try again.', {
        //         autoClose: 3000,
        //     });
        // }
        await getCategoryFields(params?.id);
        window.location.reload();
        closeDeletePopup();

    };

    const accessRole = useSelector((state) => state?.auth?.user?.user_info?.meta_data);

    return (
        <>
            {isAllowedToAccess(accessRole, 'mainwebsite_role', 'designer') ? (
                <>
                    <Box position="static" sx={{ borderRadius: 3, color: 'black' }}>
                        <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: { xs: 'flex-start', md: 'space-between' },
                                width: '100%'
                            }}>
                                <Typography sx={{
                                    fontSize: { xs: '20px', sm: '24px', md: '28px' },
                                    mb: { xs: 2, md: 0 },
                                    textAlign: {}
                                }}>
                                    Category Fields
                                </Typography>

                                <Box sx={{
                                    display: 'flex',
                                    gap: { xs: 1, md: 0.5 },
                                    flexDirection: { xs: 'column', md: 'row' },
                                    justifyContent: { xs: 'center', md: 'flex-end' },
                                    width: { xs: '100%', md: 'auto' },
                                    alignItems: 'center'
                                }}>
                                    {isAllowedToAccess(accessRole, 'mainwebsite_role', 'manager') &&
                                        <Button
                                            variant='contained'
                                            sx={{
                                                backgroundColor: 'midnightblue',
                                                textTransform: 'none',
                                                fontSize: { xs: '12px', sm: '14px', md: '16px' },
                                                width: { xs: '100%', sm: 'auto' }
                                            }}
                                            onClick={functionAddCategoryField}
                                        >
                                            Add Category Field
                                        </Button>
                                    }
                                </Box>
                            </Box>
                        </Toolbar>
                    </Box>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow >
                                    {columns.map((column) => (
                                        <TableCell key={column.id} >{column.name}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={columns.length} align="center">
                                            <Loading />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    categoryFielsData?.map((card) => (
                                        <TableRow key={card.id}>
                                            <TableCell>{card.id}</TableCell>
                                            <TableCell>{card.fieldName}</TableCell>
                                            <TableCell>{card.fieldValue}</TableCell>
                                            <TableCell>{card.type}</TableCell>
                                            <TableCell>
                                                {isAllowedToAccess(accessRole, 'mainwebsite_role', 'manager') &&
                                                    <Button variant='contained' onClick={() => functionEdit(card)} style={{ backgroundColor: 'green', color: 'white', textTransform: 'none' }}>Edit</Button>
                                                }
                                                {isAllowedToAccess(accessRole, 'mainwebsite_role', 'admin') &&
                                                    <Button variant='outlined' onClick={() => functionDelete(card.id)} style={{ color: 'red', textTransform: 'none' }}>Delete</Button>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <Typography>You don't have any permission</Typography>
            )}

            {/* Add Dialog */}
            <Dialog open={open} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Create Category Field</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeAddCategoryFieldPopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleCategoryFieldValueSubmit}>
                        <Stack spacing={2} margin={2}>
                            <TextField variant='outlined' value={fieldName} onChange={e => setFieldName(e.target.value)} label="Field Name" />
                            <TextField variant='outlined' value={fieldValue} onChange={e => setFieldValue(e.target.value)} label="Field Value" />
                            <TextField variant='outlined' value={fieldType} onChange={e => setFieldType(e.target.value)} label="Field Type" />
                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeAddCategoryFieldPopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Submit</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Edit Dialog */}
            <Dialog open={editOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Edit Category Field Value</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeEditPopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleCategoryFieldValueEditSubmit}>
                        <Stack spacing={2} margin={2}>

                            <TextField required variant='outlined' value={editFieldName} onChange={e => setEditFieldName(e.target.value)} label="Field Name" />
                            <TextField variant='outlined' value={editFieldValue} onChange={e => setEditFieldValue(e.target.value)} label="Field Value" />
                            <TextField variant='outlined' value={editFieldType} onChange={e => setEditFieldType(e.target.value)} label="Field Type" />

                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeEditPopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Update</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Delete Dialog */}
            <Dialog open={deleteOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', mt: 3 }}>
                        <Typography variant="h5" sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
                            Delete Category Field
                        </Typography>
                    </DialogTitle>
                    <IconButton onClick={closeDeletePopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent sx={{ mt: 4 }}>
                    <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                        Are you sure you want to delete this category field?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button variant="outlined" onClick={closeDeletePopup}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="error" onClick={handleCategoryFieldValueDelete}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default CategoryFields;
