import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Dialog, DialogContent, Pagination, DialogTitle, FormControl, IconButton, InputLabel, Menu, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemIcon from '@mui/material/ListItemIcon';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createBundleData, fetchBundleData } from '../../redux/actions/bundleDataActions';
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router-dom';
import { getAllTasks } from '../../redux/actions/tasksActions';
import { format } from 'date-fns';

function AllTasks() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [editMultiCardValue, setEditMultiCardValue] = useState(null);

  const handleMenuClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setEditCardId(id)
    setEditMultiCardValue(id);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    setEditCardId(null);
    setEditMultiCardValue(null);
  };

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const limit = 50;

  const handlePageChange = async (event, value) => {
    console.log("value", value);
    setPage(value);
    await dispatch(fetchBundleData(value, limit, search));
  };

  const bundleCard = useSelector((state) => state?.bundle?.bundleData?.data);
  const [allTasks, setAllTasks] = useState([]);

  useEffect(() => {
    const getAllTasksData = async () => {
      setLoading(true);
      let rsp = await getAllTasks(page, limit);
      console.log("All Tasks", rsp);
      if (rsp?.data?.data) {
        setAllTasks(rsp?.data?.data?.rows);
      }
      let total = rsp?.data?.data?.total;
      let pages = Math.ceil(total / Number(limit));
      setTotalPages(pages);
      setLoading(false);
    };
    getAllTasksData();
  }, [dispatch, page, limit])
  const columns = [
    { id: 'id', name: 'Task Id' },
    { id: 'title', name: 'Title' },
    { id: 'assignTo', name: 'Assign to' },
    { id: 'priority', name: 'Priority' },
    { id: 'description', name: 'Description' },
    { id: 'actions', name: 'Actions' }
  ];

  const handleCopyToClipboard = (id) => {
    navigator.clipboard.writeText(id)
      .then(() => {
        console.log('Copied to clipboard:', id);
        toast.success('Copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy:', err);
      });
  };



  //edit cards 
  const [editCardId, setEditCardId] = useState('');
  const [editOpen, setEditOpen] = useState(false);
  const [editPaymentStatus, setEditPaymentStatus] = useState('');


  const functionBundleEditPaymentStatus = useCallback((card) => {
    console.log("inside Edit", card);
    setEditCardId(card.id);
    setEditOpen(true);
    setEditPaymentStatus(card?.payment_status);
  }, []);

  const closeBundleEditPopup = () => {
    setEditOpen(false);
  };

  const handleCardsEditSubmit = async (e) => {
    e.preventDefault();
    const updatedCards = {
      id: editCardId, payment_status: editPaymentStatus
    }
    setLoading(true);
    await createBundleData(updatedCards);
    await dispatch(fetchBundleData(page, limit));
    setLoading(false);


    closeBundleEditPopup();
  };

  // toggle Button

  const [showCommentsBtn, setShowCommentsBtn] = useState(false);

  const handleComments = () => {
    setShowCommentsBtn(!showCommentsBtn);
  };

  useEffect(() => {

  }, [showCommentsBtn]);

  const navigate = useNavigate();

  return (
    <div>
      <Box >
        <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
          <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            width: '100%',
            justifyContent: { xs: 'flex-start', md: 'space-between' }
          }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}>
              <Typography sx={{
                fontSize: { xs: '20px', sm: '24px', md: '28px' },
                mb: { xs: 2, md: 0 }
              }}>
                All Tasks
              </Typography>
              <Button
                variant='outlined'
                sx={{
                  color: showCommentsBtn ? '#424242' : '#616161',
                  borderColor: showCommentsBtn ? '#bdbdbd' : '#e0e0e0',
                  backgroundColor: showCommentsBtn ? '#bdbdbd' : '#eeeeee',
                  borderRadius: 4
                }}
                onClick={handleComments}
              >
                Show Comments
              </Button>
              {/* <TextField
                                size="small"
                                placeholder="Search"
                                sx={{
                                    width: { xs: '100%', sm: '200px' },
                                    borderRadius: '20px',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '20px'
                                    }
                                }}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SearchIcon
                                                style={{ cursor: 'pointer' }}
                                                onClick={handleSearch}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            /> */}
            </Box>

            <Box sx={{
              display: 'flex',
              gap: { xs: 1, md: 0.5 },
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: { xs: 'center', md: 'flex-end' },
              width: { xs: '100%', md: 'auto' },
              alignItems: 'center'
            }}>
              <Button
                variant='contained'
                sx={{
                  backgroundColor: 'midnightblue',
                  textTransform: 'none',
                  fontSize: { xs: '12px', sm: '14px', md: '16px' },
                  width: { xs: '100%', sm: 'auto' }
                }}

              >
                Add Card
              </Button>
            </Box>
          </Box>
        </Toolbar>


        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow >
                {columns.map((column) => (
                  <TableCell key={column.id}>{column.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    <Loading />
                  </TableCell>
                </TableRow>
              ) : (
                allTasks && allTasks?.map((card, index) => (
                  <TableRow key={index}>
                    <TableCell onClick={() => handleCopyToClipboard(card.id)} style={{ cursor: 'pointer' }}>{card.task_id}</TableCell>
                    <TableCell>

                      <Typography>{card?.title}</Typography>


                    </TableCell>

                    <TableCell>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography key={index} variant="caption" color="initial">
                          <span style={{ color: '#616161' }}>Employee Name: </span>{card?.user?.first_name} {card?.user?.last_name}
                        </Typography>
                        <Typography key={index} variant="caption" color="initial">
                          <span style={{ color: '#616161' }}>Due Date: </span>
                          {card?.due_date ? format(new Date(card.due_date), 'dd MMM yyyy') : ''}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell>{card?.priority}</TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography key={index} variant="caption" color="initial">
                          <span style={{ color: '#616161' }}>Description: </span>{card?.description}
                        </Typography>
                        {showCommentsBtn ? (
                          <>
                            {card?.comments?.map((comment, index) => (
                              <Typography key={index} variant="caption" color="initial">
                                {/* <span style={{ color: '#616161' }}>Field Name: </span>{comment?.field_name} | <span style={{ color: '#616161' }}>Field Name: </span>{comment?.field_value} */}
                              </Typography>
                            ))}
                          </>
                        ) : (
                          <Typography variant="body1" color="initial"></Typography>
                        )}
                      </Box>
                    </TableCell>


                    {/* <TableCell>
                      <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={(event) => handleMenuClick(event, card.id)}
                      >
                        <MoreVertIcon />
                      </IconButton>


                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={menuOpen && editCardId === card?.id && editMultiCardValue === card?.id}
                        onClose={handleMenuClose}
                      >
                        <MenuItem
                          onClick={() => functionBundleEditPaymentStatus(card)}
                        >
                          <ListItemIcon>
                            <EditIcon />
                          </ListItemIcon>
                          Payment Status
                        </MenuItem>
                        <MenuItem
                          onClick={() => navigate(`/edit-multiCard-value/${card?.id}`)}
                        >
                          <ListItemIcon>
                            <EditIcon />
                          </ListItemIcon>
                          Edit Multi-Card Value
                        </MenuItem>



                        <br />
                        <MenuItem
                          // onClick={() => functionDelete(card.id)}
                          sx={{
                            color: 'red'
                          }}>
                          <ListItemIcon sx={{
                            color: 'red'
                          }}>
                            <DeleteIcon />
                          </ListItemIcon>
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableCell> */}
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1%' }}>
          <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
        </Box>
      </Box>
      <Dialog open={editOpen} fullWidth maxWidth="sm">
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
            <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Edit Bundle Card</Typography>
          </DialogTitle>
          <IconButton onClick={closeBundleEditPopup}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <form onSubmit={handleCardsEditSubmit}>
            <Stack spacing={2} margin={2}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Payment Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Payment Status"
                  value={editPaymentStatus}
                  onChange={e => setEditPaymentStatus(e.target.value)}
                >
                  <MenuItem value="not_needed">Not Needed</MenuItem>
                  <MenuItem value="captured">Captured</MenuItem>
                </Select>
              </FormControl>

              <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeBundleEditPopup}>Cancel</Button>
                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Update</Button>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default AllTasks