import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const getDesignCategory = async (page, limit, search='') => {
    const data = await api.designCategory(page, limit, search);
    return data;
};

export const addDesignCategoryData = async (formData) => {
    const data = await api.addDesignCategory(formData);
    return data;
};

export const editDesignCategoryData = async (formData) => {
    const data = await api.editDesignCategory(formData?.id, formData);
    return data;
};

export const getCategoryFields = async (id) => {
    const data = await api.categoryFields(id);
    return data;
};

export const addCategoryFieldsData = async (formData) => {
    const data = await api.addCategoryFields(formData.id, formData);
    return data;
};

export const editCategoryFieldData = async (formData) => {
    const data = await api.editCategoryFields(formData?.id, formData?.field_id, formData);
    return data;
};

export const deleteCategoryFieldData = async (formData) => {
    const data = await api.deleteCategoryFields(formData?.id, formData?.field_id);
    return data;
};