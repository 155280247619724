import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, Menu, InputLabel, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Toolbar, Typography, InputAdornment, Pagination, Link, Chip, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createCardEntyrData, deleteCardEntryData, fetchCardEntryData, updateCardEntryData } from '../../redux/actions/cardEntryActions';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import Loading from '../../components/Loading';
import { fetchAllCardsData } from '../../redux/actions/allCardsActions';
import { toast } from 'react-toastify';
import { isAllowedToAccess } from '../../components/PermissionManager';
import { addDesignCategoryData, editDesignCategoryData, getDesignCategory } from '../../redux/actions/designCategoryActions';
import SortIcon from '@mui/icons-material/Sort';
import PersonIcon from '@mui/icons-material/Person';
import PublicIcon from '@mui/icons-material/Public';
import DescriptionIcon from '@mui/icons-material/Description';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PeopleIcon from '@mui/icons-material/People';
import EditIcon from '@mui/icons-material/Edit';
import CategoryIcon from '@mui/icons-material/Category';
import DeleteIcon from '@mui/icons-material/Delete';


function DesignCategory() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const cards = useSelector((state) => state?.cards?.cardsData?.data);
    const [loading, setLoading] = useState(true);
    const [designCategory, setDesignCategory] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenuClick = (event, id) => {
        setAnchorEl(event.currentTarget);
        setEditDesignCategoryId(id);
        setDeleteEntryeId(id);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setEditDesignCategoryId(null);
        setDeleteEntryeId(null);
    };

    // pagination 

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [search, setSearch] = useState('');
    const limit = 50;

    useEffect(() => {
        getDesignCategory(page, limit);
    }, [page]);

    const handlePageChange = async (event, value) => {
        console.log("value", value);
        await setPage(value);
        await getDesignCategory(value, limit);
    };

    const handleSearch = async () => {
        setPage(1);
        setLoading(true);
        await getDesignCategory(1, limit, search);
        setLoading(false);
    };



    // Fetch card Entry data on component mount
    useEffect(() => {
        const fetchCardEntry = async () => {
            setLoading(true);
            let rsp = await getDesignCategory(page, limit, search);
            if (rsp) {
                console.log('Design Category Data', rsp);
                setDesignCategory(rsp?.data?.data);
                let total = rsp?.data?.data?.total;
                let pages = Math.ceil(total / Number(limit));
                setTotalPages(pages);
                setLoading(false);

            }

        };

        fetchCardEntry();
    }, [dispatch, page, limit, search]);

    const columns = [
        { id: 'entryId', name: 'Id' },
        { id: 'title', name: 'Title' },
        { id: 'scope', name: 'Scope' },
        { id: 'visibility', name: 'Visibility' },

        { id: 'actions', name: 'Actions' }
    ];

    // Add functionality
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [scope, setScope] = useState('');
    const [description, setDescription] = useState('');
    const [isPublic, setIsPublic] = useState(false);
    const [isVisibleToDesigner, setIsVisibleToDesigner] = useState(true);



    const functionAddDesignCategory = () => {
        setOpen(true);
    };

    const closeDesignCategoryPopup = () => {
        setOpen(false);
    };



    const handleDesignCategorySubmit = async (e) => {
        e.preventDefault();
        const newCards = {
            name, scope, description, isPublic, isVisibleToDesigner
        }
        const response = await addDesignCategoryData(newCards);

        // if (response?.status === 'success') {
        //     toast.success('Card Entry submitted successfully!', {
        //         autoClose: 3000,
        //     });
        // } else {
        //     toast.error('Failed to submitted card Entry. Please try again.', {
        //         autoClose: 3000,
        //     });
        // }
        await getDesignCategory(page, limit, search);
        setName('');
        setScope('');
        closeDesignCategoryPopup();


    }

    // Edit functionality
    const [editOpen, setEditOpen] = useState(false);
    const [editDesignCategoryId, setEditDesignCategoryId] = useState('');
    const [editName, setEditName] = useState('');
    const [editScope, setEditScope] = useState('');
    const [editDescription, setEditDescription] = useState('');
    

    const functionDesignCategoryEdit = useCallback((category) => {
        console.log("inside Edit", category);
        setEditDesignCategoryId(category.id);
        setEditName(category.name);
        setEditScope(category.scope);

        setEditOpen(true);
    }, []);

    const closeDesignCategoryEditPopup = () => {
        setEditOpen(false);
    };

    const handleDesignCategoryEditSubmit = async (e) => {
        e.preventDefault();
        const updatedCards = {
            id: editDesignCategoryId, name: editName, scope: editScope, description: editDescription
        }
        const response = await editDesignCategoryData(updatedCards);
        console.log("update card entry response", response);
        // if (response?.payload?.status === 'success') {


        //     toast.success('Card Entry updated successfully!', {
        //         autoClose: 3000, // Auto-close after 3 seconds
        //     });
        // } else {
        //     toast.error('Failed to update card Entry. Please try again.', {
        //         autoClose: 3000,
        //     });
        // }
        await getDesignCategory(page, limit, search);
        closeDesignCategoryEditPopup();
    };



    // Delete functionality
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteEntryId, setDeleteEntryeId] = useState('');

    const functionDelete = useCallback((id) => {
        setDeleteEntryeId(id);
        setDeleteOpen(true);
    }, []);
    const closeDeletePopup = () => {
        setDeleteOpen(false);
    };
    const handleCardEntryDelete = async () => {
        if (deleteEntryId) {
            const response = await dispatch(deleteCardEntryData(deleteEntryId));
            console.log("update card entry response", response);
            if (response?.status === 200) {
                toast.success('Card Deleted successfully!', {
                    autoClose: 3000,
                });
            } else {
                toast.error('Failed to Deleted card. Please try again.', {
                    autoClose: 3000,
                });
            }

            await dispatch(fetchCardEntryData());
            closeDeletePopup();
        }
    };

    const handleDownload = async (card) => {
        if (!card?.final_url) return;

        try {
            const response = await fetch(card.final_url);
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);

            const urlParts = card.final_url.split('.');
            const extension = urlParts[urlParts.length - 1];

            const filename = card.title + `final.${extension}`;

            const link = document.createElement('a');
            link.href = url;
            link.download = filename;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Download failed:", error);
        }
    };

    const getFileType = (url) => {
        if (!url) return 'Unknown';

        const extension = url.split('.').pop().toLowerCase();

        switch (extension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return 'image';
            case 'mp4':
            case 'avi':
            case 'mov':
            case 'wmv':
                return 'video';
            case 'pdf':
                return 'pdf';
            default:
                return 'Unknown';
        }
    };

    // Function to format the date in Indian format with time
    const formatDate = (dateString) => {
        if (!dateString) return '';

        const date = new Date(dateString);
        return date.toLocaleString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        });
    };

    const accessRole = useSelector((state) => state?.auth?.user?.user_info?.meta_data);

    return (
        <>
            {isAllowedToAccess(accessRole, 'mainwebsite_role', 'designer') ? (
                <>
                    <Box position="static" sx={{ borderRadius: 3, color: 'black' }}>
                        <Toolbar sx={{ borderRadius: 2, flexWrap: 'wrap', display: 'flex', alignItems: 'center' }}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                alignItems: 'center',
                                width: '100%',
                                justifyContent: { xs: 'flex-start', md: 'space-between' }
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                }}>
                                    <Typography sx={{
                                        fontSize: { xs: '20px', sm: '24px', md: '28px' },
                                        mb: { xs: 2, md: 0 }
                                    }}>
                                        Design Category
                                    </Typography>
                                    <TextField
                                        size="small"
                                        placeholder="Search"
                                        sx={{
                                            width: { xs: '100%', sm: '200px' },
                                            borderRadius: '20px',
                                            '& .MuiOutlinedInput-root': {
                                                borderRadius: '20px'
                                            }
                                        }}
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <SearchIcon
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={handleSearch}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Box>

                                <Box sx={{
                                    display: 'flex',
                                    gap: { xs: 1, md: 0.5 },
                                    flexDirection: { xs: 'column', md: 'row' },
                                    justifyContent: { xs: 'center', md: 'flex-end' },
                                    width: { xs: '100%', md: 'auto' },
                                    alignItems: 'center'
                                }}>
                                    {isAllowedToAccess(accessRole, 'mainwebsite_role', 'manager') &&
                                        <Button
                                            variant='contained'
                                            sx={{
                                                backgroundColor: 'midnightblue',
                                                textTransform: 'none',
                                                fontSize: { xs: '12px', sm: '14px', md: '16px' },
                                                width: { xs: '100%', sm: 'auto' }
                                            }}
                                            onClick={functionAddDesignCategory}
                                        >
                                            Add
                                        </Button>
                                    }

                                </Box>
                            </Box>
                        </Toolbar>
                    </Box>

                    <TableContainer component={Paper} sx={{ borderRadius: 2, boxShadow: 3 }}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
                                    {columns.map((column) => (
                                        <TableCell key={column.id} sx={{ fontWeight: 'bold' }}>
                                            {column.name} <SortIcon fontSize="small" sx={{ color: '#757575', ml: 1 }} />
                                        </TableCell>
                                    ))}

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {loading ? (
                                    <TableRow>
                                        <TableCell colSpan={columns.length} align="center">
                                            <Loading />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    designCategory?.Records?.map((card, index) => (
                                        <TableRow
                                            key={card?.id}
                                            sx={{
                                                '&:hover': { backgroundColor: '#f1f1f1' },
                                                transition: '0.3s ease-in-out'
                                            }}
                                        >
                                            <TableCell>
                                                <Chip label={card?.id} color="primary" size="small" />
                                            </TableCell>
                                            <TableCell>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                                                    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                                                        <PersonIcon fontSize="small" sx={{ mr: 1, color: '#616161' }} />
                                                        <b>Name:</b> {card?.name}
                                                    </Box>

                                                    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                                                        <DescriptionIcon fontSize="small" sx={{ mr: 1, color: '#616161' }} />
                                                        <b>Description:</b> {card?.description}
                                                    </Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                                                    <PublicIcon fontSize="small" sx={{ mr: 1, color: '#616161' }} />
                                                    <b>Scope:</b> {card?.scope}
                                                </Box>
                                            </TableCell>
                                            <TableCell>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                                                    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                                                        <VisibilityIcon fontSize="small" sx={{ mr: 1, color: '#616161' }} />
                                                        <b>Public Visibility:</b> {card?.isPublic?.toString()}
                                                    </Box>
                                                    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                                                        <PeopleIcon fontSize="small" sx={{ mr: 1, color: '#616161' }} />
                                                        <b>Designer Visibility:</b> {card?.isVisibleToDesigner?.toString()}
                                                    </Box>
                                                </Box>
                                            </TableCell>

                                            <TableCell>
                                                <Tooltip title="More Actions">
                                                    <IconButton
                                                        aria-controls="simple-menu"
                                                        aria-haspopup="true"
                                                        onClick={(event) => handleMenuClick(event, card.id)}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Menu
                                                    id="simple-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={menuOpen && editDesignCategoryId === card.id && deleteEntryId === card.id}
                                                    onClose={handleMenuClose}
                                                >
                                                    {isAllowedToAccess(accessRole, 'mainwebsite_role', 'manager') && (
                                                        <>
                                                            <MenuItem onClick={() => functionDesignCategoryEdit(card)}>
                                                                <EditIcon fontSize="small" sx={{ mr: 1 }} />
                                                                Edit
                                                            </MenuItem>
                                                            <MenuItem onClick={() => navigate(`category-fields/${card.id}`)}>
                                                                <CategoryIcon fontSize="small" sx={{ mr: 1 }} />
                                                                Category Fields
                                                            </MenuItem>
                                                        </>
                                                    )}
                                                    {isAllowedToAccess(accessRole, 'mainwebsite_role', 'admin') && (
                                                        <MenuItem onClick={() => functionDelete(card.id)}>
                                                            <DeleteIcon fontSize="small" sx={{ mr: 1, color: 'error.main' }} />
                                                            Delete
                                                        </MenuItem>
                                                    )}
                                                </Menu>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1%' }}>
                        <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
                    </Box>
                </>
            ) : (
                <Typography>You don't have any permission</Typography>
            )}

            {/* Add Dialog */}
            <Dialog open={open} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Create Design Category</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeDesignCategoryPopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleDesignCategorySubmit}>
                        <Stack spacing={2} margin={2}>

                            <TextField variant='outlined' value={name} onChange={e => setName(e.target.value)} label="Category Name" />
                            <TextField variant='outlined' value={scope} onChange={e => setScope(e.target.value)} label="Scope" />
                            <TextField variant='outlined' value={description} onChange={e => setDescription(e.target.value)} label="Description" />
                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeDesignCategoryPopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Submit</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Edit Dialog */}
            <Dialog open={editOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Edit Design Category</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeDesignCategoryEditPopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <form onSubmit={handleDesignCategoryEditSubmit}>
                        <Stack spacing={2} margin={2}>

                            <TextField required variant='outlined' value={editName} onChange={e => setEditName(e.target.value)} label="Name" />
                            <TextField variant='outlined' value={editScope} onChange={e => setEditScope(e.target.value)} label="Scope" />
                            <TextField variant='outlined' value={editDescription} onChange={e => setEditDescription(e.target.value)} label="Description" />

                            <Stack sx={{ flexDirection: 'row', justifyContent: 'center', gap: 2 }}>
                                <Button variant='outlined' sx={{ textTransform: 'none' }} onClick={closeDesignCategoryEditPopup}>Cancel</Button>
                                <Button variant='contained' sx={{ textTransform: 'none' }} type='submit'>Update</Button>
                            </Stack>
                        </Stack>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Delete Dialog */}
            <Dialog open={deleteOpen} fullWidth maxWidth="sm">
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <DialogTitle sx={{ flexGrow: 1, textAlign: 'center', marginTop: 3 }}>
                        <Typography variant='h5' sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>Delete Card</Typography>
                    </DialogTitle>
                    <IconButton onClick={closeDeletePopup}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent sx={{ marginTop: 4 }}>
                    <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                        Are you sure you want to delete this card group?
                    </Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Button variant="outlined" onClick={closeDeletePopup}>Cancel</Button>
                    <Button variant="contained" color="error" onClick={handleCardEntryDelete}>Delete</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DesignCategory;
