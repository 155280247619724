import * as actionTypes from "../actionTypes";
import * as api from "../../api/index";

export const fetchCardEntryValueRequest = () => ({
    type: actionTypes.FETCH_CARD_ENTRY_VALUES_REQUEST,
});
export const fetchCardEntryValueSuccess = (data) => ({
    type: actionTypes.FETCH_CARD_ENTRY_VALUES_SUCCESS,
    payload: data,
});
export const fetchCardEntryValueFailure = (error) => ({
    type: actionTypes.FETCH_CARD_ENTRY_VALUES_FAILURE,
    payload: error,
});

export const fetchCardEntryValueData = (id) => {

    return async (dispatch) => {
        dispatch(fetchCardEntryValueRequest());
        try {
            const {data} = await api.cardEntryValues(id);
            dispatch(fetchCardEntryValueSuccess(data));
            return data;
        } catch (error) {
            dispatch(fetchCardEntryValueFailure(error));
        }
    };
};

export const createCardEntryValueRequest = () => ({
    type: actionTypes.CREATE_CARD_ENTRY_VALUES_REQUEST,
});
export const createCardEntryValueSuccess = (data) => ({
    type: actionTypes.CREATE_CARD_ENTRY_VALUES_SUCCESS,
    payload: data
});
export const createCardEntryValueFailure = (error) => ({
    type: actionTypes.CREATE_CARD_ENTRY_VALUES_FAILURE,
    payload: error,
});

export const createCardEntryValueData = (newCardData) => {
    return async (dispatch) => {
        dispatch(createCardEntryValueRequest());
        try{
            const {data} = await api.createCardEntryValues(newCardData);
            dispatch(createCardEntryValueSuccess(data));
            return data;
        }catch(error) {
            dispatch(createCardEntryValueFailure(error));
        }
    }
};

export const updateCardEntryValueRequest = () => ({
    type: actionTypes.UPDATE_CARD_ENTRY_VALUES_REQUEST,
});
export const updateCardEntryValueSuccess = (data) => ({
    type: actionTypes.UPDATE_CARD_ENTRY_VALUES_SUCCESS,
    payload: data,
});
export const updateCardEntryValueFailure = (error) => ({
    type: actionTypes.UPDATE_CARD_ENTRY_VALUES_FAILURE,
    payload: error,
});

export const updateCardEntryValueData = (updateData) => {
    return async (dispatch) => {
        dispatch(updateCardEntryValueRequest());
        try{
            const response = await api.updateCardEntryValues(updateData.id, updateData);
            const updatedCard = response?.data;
            const successAction = updateCardEntryValueSuccess(updatedCard);
            dispatch(successAction);
            return successAction;

        } catch(error) {
            const failureAction = updateCardEntryValueFailure(error.message);
            dispatch(failureAction);
            return failureAction;
        }
    };
};

export const deleteCardEntryValueRequest = () => ({
    type: actionTypes.DELETE_CARD_ENTRY_VALUES_REQUEST,
});
export const deleteCardEntryValueSuccess = (id) => ({
    type: actionTypes.DELETE_CARD_ENTRY_VALUES_SUCCESS,
    payload: id,
});
export const deleteCardEntryValueFailure = (error) => ({
    type: actionTypes.DELETE_CARD_ENTRY_VALUES_FAILURE,
    payload: error,
});

export const deleteCardEntryValueData = (id) => {
    return async (dispatch) => {
        dispatch(deleteCardEntryValueRequest());
        try{
            const data = await api.deleteCardEntryValues(id);
            dispatch(deleteCardEntryValueSuccess(id));
            return data;
        } catch(error) {
            dispatch(deleteCardEntryValueFailure(error));
        }
    };
};