import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Paper,
    Typography,
    Grid,
    Avatar,
    Chip,
    Divider,
    CircularProgress,
    Button,
    Card,
    CardContent,
    IconButton,
    Tooltip
} from '@mui/material';
import {
    ArrowBack,
    Phone,
    Email,
    LocationOn,
    CalendarToday,
    Star,
    Person,
    Verified,
    ContentCopy,
    WhatsApp,
    Edit
} from '@mui/icons-material';
import { fetchSingleCustomer } from '../../redux/actions/authActions';

const CustomerDetailView = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [customer, setCustomer] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [copiedMobile, setCopiedMobile] = useState(false);

    useEffect(() => {
        fetchCustomerDetails();
    }, [id]);

    const fetchCustomerDetails = async () => {
        try {
            setLoading(true);
            const response = await fetchSingleCustomer(id);
            console.log('Customer details:', response);
            if (response.status === 200) {
                setCustomer(response?.data?.data);
            } else {
                setError('Failed to fetch customer details');
            }
        } catch (err) {
            setError('Error loading customer details');
            console.error('Error:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleCopyMobile = (countryCode, mobile) => {
        const fullNumber = `${countryCode}${mobile}`;
        navigator.clipboard.writeText(fullNumber).then(() => {
            setCopiedMobile(true);
            setTimeout(() => setCopiedMobile(false), 2000);
        });
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
            timeZone: 'UTC'
        });
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <CircularProgress />
            </Box>
        );
    }

    if (error || !customer) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <Typography color="error">{error || 'Customer not found'}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            {/* Header */}
            <Box display="flex" alignItems="center" mb={3}>
                <IconButton onClick={() => navigate(-1)} sx={{ mr: 2 }}>
                    <ArrowBack />
                </IconButton>
                <Typography variant="h5">Customer Details</Typography>
            </Box>

            <Grid container spacing={3}>
                {/* Main Info Card */}
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
                                <Avatar
                                    src={customer.avatar}
                                    sx={{ width: 100, height: 100, mb: 2 }}
                                >
                                    {customer.firstName?.[0] || customer.mobile?.[0]}
                                </Avatar>
                                <Typography variant="h6">
                                    {customer.firstName 
                                        ? `${customer.firstName} ${customer.lastName}`
                                        : '---'
                                    }
                                </Typography>
                                <Typography variant="caption" color="textSecondary">
                                    ID: {customer.id}
                                </Typography>
                            </Box>

                            <Box display="flex" justifyContent="center" gap={1} mb={2}>
                                {customer.isPremiumCustomer && (
                                    <Chip
                                        icon={<Star />}
                                        label="Premium"
                                        color="primary"
                                    />
                                )}
                                <Chip
                                    icon={<LocationOn />}
                                    label={customer.environment}
                                />
                            </Box>

                            <Divider sx={{ my: 2 }} />

                            {/* Contact Information */}
                            <Box>
                                <Box display="flex" alignItems="center" mb={2}>
                                    <Phone sx={{ mr: 1 }} />
                                    <Tooltip 
                                        title={copiedMobile ? "Copied!" : "Click to copy"}
                                        placement="top"
                                    >
                                        <Typography 
                                            variant="body2"
                                            onClick={() => handleCopyMobile(customer.countryCode, customer.mobile)}
                                            sx={{ 
                                                cursor: 'pointer',
                                                '&:hover': { color: 'primary.main' }
                                            }}
                                        >
                                            +{customer.countryCode} {customer.mobile}
                                            {customer.mobileVerified && 
                                                <Verified color="primary" sx={{ ml: 1, fontSize: 14 }} />
                                            }
                                        </Typography>
                                    </Tooltip>
                                </Box>

                                <Box display="flex" alignItems="center" mb={2}>
                                    <Email sx={{ mr: 1 }} />
                                    <Typography variant="body2">
                                        {customer.email || 'No email'}
                                        {customer.emailVerified && 
                                            <Verified color="primary" sx={{ ml: 1, fontSize: 14 }} />
                                        }
                                    </Typography>
                                </Box>

                                <Box display="flex" alignItems="center">
                                    <CalendarToday sx={{ mr: 1 }} />
                                    <Typography variant="body2">
                                        Joined: {formatDate(customer.createdAt)}
                                    </Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Additional Information */}
                <Grid item xs={12} md={8}>
                    <Grid container spacing={2}>
                        {/* Account Status */}
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Account Status
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={4}>
                                            <Typography variant="subtitle2">Verification Required</Typography>
                                            <Typography>{customer.isVerificationReq ? 'Yes' : 'No'}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <Typography variant="subtitle2">Account Type</Typography>
                                            <Typography>{customer.isGuestAcc ? 'Guest' : 'Regular'}</Typography>
                                        </Grid>
                                        <Grid item xs={6} sm={4}>
                                            <Typography variant="subtitle2">Tenant</Typography>
                                            <Typography>{customer.tenant}</Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Referral Information */}
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Referral Information
                                    </Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle2">My Referral Code</Typography>
                                            <Typography>{customer.myReferralCode || 'Not available'}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant="subtitle2">Referred By</Typography>
                                            <Typography>{customer.referralCode || 'Not referred'}</Typography>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Quick Actions */}
                        <Grid item xs={12}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Quick Actions
                                    </Typography>
                                    <Box display="flex" gap={2}>
                                        <Button
                                            variant="outlined"
                                            startIcon={<WhatsApp />}
                                            onClick={() => {/* Handle WhatsApp */}}
                                        >
                                            WhatsApp
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            startIcon={<Email />}
                                            onClick={() => {/* Handle Email */}}
                                        >
                                            Send Email
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            startIcon={<Edit />}
                                            onClick={() => {/* Handle Edit */}}
                                        >
                                            Edit Details
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CustomerDetailView; 